import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './NavMenu.css';
import AuthProvider from "../auth/AuthProvider";

interface IUserAccount {
    displayName: string;
    email: string;
}

interface INavMenuProps {
  authProvider?: AuthProvider;
}

interface INavMenuState {
  collapsed: boolean;
  userAccount?: IUserAccount;
}

export class NavMenu extends Component<INavMenuProps, INavMenuState> {
  static displayName = NavMenu.name;

  constructor(props: any) {
    super(props);

    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
      collapsed: true,
      userAccount: undefined,
    };
  }

  componentDidMount() {
  }

  componentDidUpdate() {
    if(this.props.authProvider && this.state.userAccount == undefined) {
      const account = this.props.authProvider.getAccount();
      if(account) {
        this.setState({
          ...this.state,
          userAccount: {
            displayName: account.name,
            email: account.userName
          }
        });
      }
    }
  }
 
  toggleNavbar() {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }


  render() {
    return (<nav className="navbar fixed-top navbar-expand-lg">
      <div className="container">
        <div className="navbar-translate">
          <a className="navbar-brand" href="https://www.konsolute.com">
            <div className="logo-image">
              <img alt="Konsolute Logo" src="./assets/img/konsolute-logo.png" className="img-fluid" />
            </div>
          </a>
          <button className="navbar-toggler" type="button" data-toggle="collapse" aria-expanded="false" aria-label="Toggle navigation">
            <span className="sr-only">Toggle navigation</span>
            <span className="navbar-toggler-icon"></span>
            <span className="navbar-toggler-icon"></span>
            <span className="navbar-toggler-icon"></span>
          </button>
        </div>
        <div className="collapse navbar-collapse">
          <ul className="navbar-nav ml-auto mr-auto">
            <li className="nav-item">
              <a href="https://www.konsolute.com/" className="nav-link" target="_blank">
                Home
                </a>
            </li>
            <li className="nav-item dropdown">
              <a className="nav-link dropdown-toggle" href="https://www.konsolute.com/products" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                Products
          </a>
              <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                <a className="dropdown-item" href="https://www.konsolute.com/products/reflekt/">Reflekt</a>
                <a className="dropdown-item" href="https://www.konsolute.com/products/auto-classifier/">Auto Classifier</a>
                <a className="dropdown-item" href="https://www.konsolute.com/products/onboard/">Onboard</a>
                <a className="dropdown-item" href="https://www.konsolute.com/products/virtual-academy/">Virtual Academy</a>
                <a className="dropdown-item" href="https://www.konsolute.com/products/iso-certified-qms-system/">ISO Certified QMS System</a>
                <a className="dropdown-item" href="https://www.konsolute.com/products/kolumbus/">Kolumbus</a>
                <a className="dropdown-item" href="https://www.konsolute.com/products/modern-intranet/">Modern Intranet</a>
              </div>
            </li>
            <li className="nav-item dropdown">
              <a className="nav-link dropdown-toggle" href="https://www.konsolute.com/services/" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                Services
          </a>
              <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                <a className="dropdown-item" href="https://www.konsolute.com/services/cloud-strategy-and-readiness/">Cloud Strategy and Readiness</a>
                <a className="dropdown-item" href="https://www.konsolute.com/services/cloud-migration-services/">Cloud Migration Services</a>
                <a className="dropdown-item" href="https://www.konsolute.com/services/cloud-modernisation/">Cloud Modernisation</a>
                <a className="dropdown-item" href="https://www.konsolute.com/services/security-compliance/">Security &amp; Compliance</a>
              </div>
            </li>
            <li className="nav-item">
              <a href="https://www.konsolute.com/support/" className="nav-link" target="_blank">
                Support
                </a>
            </li>  <li className="nav-item">
              <a href="https://www.konsolute.com/about/" className="nav-link" target="_blank">
                About
                </a>
            </li>
          </ul>
          {
            this.state.userAccount &&
            <ul className="navbar-nav ml-auto">
              {this.renderAccountButton()}
            </ul>
          }
        </div>
      </div>
    </nav>);
  }

  private renderAccountButton():React.ReactFragment{
    return (<li className="button-container dropdown nav-item">
      <a href="#" className="btn btn-info btn-block nav-btn dropdown-toggle" data-toggle="dropdown" aria-expanded="true">
      <i className="material-icons">account_circle</i>&nbsp;{this.state.userAccount ? this.state.userAccount.displayName : <em>Not signed in</em> }
      <div className="ripple-container"></div></a>
        <div className="dropdown-menu dropdown-menu-right">
          <div className="card-body">
          {this.state.userAccount ? 
            (<><strong>Signed in as:</strong>&nbsp;{`${this.state.userAccount.displayName} (${this.state.userAccount.email})`}</>)
            : (<strong><em>Not signed in</em></strong>)
          }
      </div>
    </div>
  </li>);
  }

}
