import * as React from 'react';
import LineChartCard from '../../../components/Report/widgets/LineChartCard';
import PieChartCard from '../../../components/Report/widgets/PieChartCard';
import TableCard from '../../../components/Report/widgets/TableCard';
import CounterCard from '../../../components/Report/widgets/CounterCard';
import { LineChartOptions, PieChartData } from '../../../models/Report/IChart';
import { IOrphanedTeam } from '../../../models/Report/ITeam';
import { IOrphanedOverview, IOverviewReport, ITeamsCreatedSummary, ITeamsReport } from '../../../models/Report/ITabModels';
import Utilities from '../../../services/Utils';
import { ReportStrings } from '../../../settings/Report/ReportSettings';
import ClampLines from 'react-clamp-lines';
import uniqid from 'uniqid';

export interface ITeamsTabProps {
  onTeamClick(teamId: string): void;
  allTeams?: ITeamsReport;
  orphanedOverviewInfo?: IOrphanedOverview;
  reportData?: IOverviewReport;
  orphanedTeams: IOrphanedTeam[];
  creationData?: ITeamsCreatedSummary[];
  predictionData?: ITeamsCreatedSummary[];
  onTeamClick(teamId: string): void;
  loading?: boolean;
  creationPeriod: number;
  teamsTablePageChange(pageNumber: number): void;
}

export interface ICreationChart extends LineChartOptions {
  data: {
    period: string;
    count: number;
  }[];
}

export interface ITeamsTabState {
  visibilityChartData: PieChartData;
  creationChartData: ICreationChart;
  predictionData: ICreationChart;
  activeView: number;
}

export default class TeamsTab extends React.PureComponent<ITeamsTabProps, ITeamsTabState> {

  constructor(props: ITeamsTabProps) {
    super(props);

    this.state = {
      creationChartData: {
        primaryXAxis: { valueType: 'DateTime', labelFormat: 'MMM y', title: 'Teams created by month' },
        legendSettings: { visible: true },
        tooltip: { enable: true, shared: false },
        data: []
      },
      predictionData: {
        primaryXAxis: { valueType: 'DateTime', labelFormat: 'MMM y', title: 'Teams created by month' },
        legendSettings: { visible: true },
        tooltip: { enable: true, shared: false },
        data: []
      },
      visibilityChartData: {
        dataLabel: { visible: true, position: 'Outside', name: 'x' },
        data: []
      },
      activeView: 0
    }
  }

  public componentDidMount(): void {
    this.formatVisibilityChartData();
    this.formatCreationChartData();
    this.formatPredictionChartData();
  }

  public componentDidUpdate(prevProps: ITeamsTabProps) {
    if (prevProps.orphanedOverviewInfo !== this.props.orphanedOverviewInfo) {
      this.formatVisibilityChartData();
    }

    if (prevProps.creationData !== this.props.creationData) {
      this.formatCreationChartData();
      this.formatPredictionChartData();
    }
  }


  public render() {
    return <div>
      {this.renderSectionToggles()}
      {this.renderContent()}
    </div>;
  }

  private renderContent(): React.ReactElement {
    const { activeView } = this.state;
    switch (activeView) {
      case 1: return this.renderOrphanedSection();
      default: return this.renderAllTeamsSection();
    }
  }

  private renderOrphanedSection(): React.ReactElement {
    const { visibilityChartData: pieChartData } = this.state;
    const { orphanedOverviewInfo, orphanedTeams } = this.props;
    return <>
      <h4 className={`title`}>{ReportStrings.teams.orphanedTeams.title}</h4>
      <div className="row text-center">
        <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
          <CounterCard title={ReportStrings.teams.orphanedTeams.teamsCounter.title} value={orphanedTeams.length} icon="notifications_none" colour="danger">
            <div className="stats">
              <i className="material-icons text-danger">privacy_tip</i>
              <ClampLines
                id={`countercard_${uniqid()}`}
                text={ReportStrings.teams.orphanedTeams.teamsCounter.description}
                lines={3}
                ellipsis="..."
                buttons={true}
                className="clamped-text"
                moreText={'Read more'}
                lessText={'Read less'} />
            </div>
          </CounterCard>
        </div>
        <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
          <CounterCard title={ReportStrings.teams.orphanedTeams.storageCounter.title} value={Utilities.convertToStorageSizeString(orphanedOverviewInfo?.totalStorageUsed)} icon="storage" colour="warning">
            <div className="stats">
              <i className="material-icons text-warning">privacy_tip</i>
              <ClampLines
                id={`countercard_${uniqid()}`}
                text={ReportStrings.teams.orphanedTeams.storageCounter.description}
                lines={3}
                ellipsis="..."
                buttons={true}
                className="clamped-text"
                moreText={'Read more'}
                lessText={'Read less'} />

            </div>
          </CounterCard>
        </div>
        <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
          <CounterCard title={ReportStrings.teams.orphanedTeams.documentsCounter.title} value={orphanedOverviewInfo?.totalDocuments} icon="description" colour="warning">
            <div className="stats">
              <i className="material-icons text-warning">privacy_tip</i>
              <ClampLines
                id={`countercard_${uniqid()}`}
                text={ReportStrings.teams.orphanedTeams.documentsCounter.description}
                lines={3}
                ellipsis="..."
                buttons={true}
                className="clamped-text"
                moreText={'Read more'}
                lessText={'Read less'} />
            </div>
          </CounterCard>
        </div>
        <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
          <CounterCard title={ReportStrings.teams.orphanedTeams.foldersCounter.title} value={orphanedOverviewInfo?.totalFolders} icon="folder_open" colour="warning">
            <div className="stats">
              <i className="material-icons text-warning">privacy_tip</i>
              <ClampLines
                id={`countercard_${uniqid()}`}
                text={ReportStrings.teams.orphanedTeams.foldersCounter.description}
                lines={3}
                ellipsis="..."
                buttons={true}
                className="clamped-text"
                moreText={'Read more'}
                lessText={'Read less'} />
            </div>
          </CounterCard>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6 col-xl-8">
          <TableCard
            id={`all_orphaned_teams`}
            title={ReportStrings.teams.orphanedTeams.teamsList.title}
            description={ReportStrings.teams.orphanedTeams.teamsList.description}
            colour={`primary`}
            items={orphanedTeams}
            onTitleClick={this.props.onTeamClick}
            enablePaging={false}
            resizeable={false}
            columnInfo={[
              { headerText: "Name", field: "displayName", clipMode: "EllipsisWithTooltip", width: "250px" },
              { headerText: "Created", field: "createdDateTime", type: "datetime", format: "dd/MM/yyyy", width: "250px" }
            ]} />
        </div>
        <div className="col-lg-6 col-xl-4 text-center">
          <PieChartCard title={ReportStrings.teams.orphanedTeams.visibilityChart.title} description={ReportStrings.teams.orphanedTeams.visibilityChart.description} chartOptions={pieChartData} colour="info" />
        </div>
      </div>
    </>;
  }

  private renderAllTeamsSection(): React.ReactElement {
    const { creationChartData, predictionData } = this.state;
    const { allTeams, creationPeriod, reportData } = this.props;

    return <div>
      <h4 className={`title`}>{ReportStrings.teams.allTeams.title}</h4>
      <div className="row text-center">
        <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
          <CounterCard title={ReportStrings.teams.allTeams.teamsCounter.title} value={allTeams ? allTeams.rowCount : 0} icon="people_outline" colour="success">
            <div className="stats">
              <i className="material-icons text-success">privacy_tip</i>
              <ClampLines
                id={`countercard_${uniqid()}`}
                text={ReportStrings.teams.allTeams.teamsCounter.description}
                lines={3}
                ellipsis="..."
                buttons={true}
                className="clamped-text"
                moreText={'Read more'}
                lessText={'Read less'} />
            </div>
          </CounterCard>
        </div>
        <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
          <CounterCard title={ReportStrings.teams.allTeams.storageCounter.title} value={Utilities.convertToStorageSizeString(reportData?.totalStorageUsed)} icon="storage" colour="warning">
            <div className="stats">
              <i className="material-icons text-warning">privacy_tip</i>
              <ClampLines
                id={`countercard_${uniqid()}`}
                text={ReportStrings.teams.allTeams.storageCounter.description}
                lines={3}
                ellipsis="..."
                buttons={true}
                className="clamped-text"
                moreText={'Read more'}
                lessText={'Read less'} />
            </div>
          </CounterCard>
        </div>
        <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
          <CounterCard title={ReportStrings.teams.allTeams.documentsCounter.title} value={reportData?.totalDocuments} icon="description" colour="warning">
            <div className="stats">
              <i className="material-icons text-warning">privacy_tip</i>
              <ClampLines
                id={`countercard_${uniqid()}`}
                text={ReportStrings.teams.allTeams.documentsCounter.description}
                lines={3}
                ellipsis="..."
                buttons={true}
                className="clamped-text"
                moreText={'Read more'}
                lessText={'Read less'} />
            </div>
          </CounterCard>
        </div>
        <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
          <CounterCard title={ReportStrings.teams.allTeams.foldersCounter.title} value={reportData?.totalFolders} icon="folder_open" colour="warning">
            <div className="stats">
              <i className="material-icons text-warning">privacy_tip</i>
              <ClampLines
                id={`countercard_${uniqid()}`}
                text={ReportStrings.teams.allTeams.foldersCounter.description}
                lines={3}
                ellipsis="..."
                buttons={true}
                className="clamped-text"
                moreText={'Read more'}
                lessText={'Read less'} />
            </div>
          </CounterCard>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <TableCard
            id={`all_teams`}
            title={ReportStrings.teams.allTeams.teamsList.title}
            description={ReportStrings.teams.allTeams.teamsList.description}
            colour="info"
            items={allTeams?.results}
            currentPage={allTeams?.currentPage}
            totalPages={allTeams?.pageCount}
            resizeable={true}
            enablePaging={true}
            columnInfo={[
              { headerText: "Name", field: "displayName", clipMode: "EllipsisWithTooltip" },
              { headerText: "Description", field: "description", clipMode: "EllipsisWithTooltip" },
              { headerText: "Visibility", field: "visibility" },
              { headerText: "Owners", field: "ownersCount" },
              { headerText: "Members", field: "membersCount" },
              { headerText: "Guests", field: "guestsCount" },
              { headerText: "Created", field: "createdDateTime", type: "datetime", format: "dd/MM/yyyy" },
              { headerText: "Channels", field: "channelsCount" },
              { headerText: "Has private channels", field: "hasPrivateChannels" },
              { headerText: "Total Documents", field: "documentsCount" },
              { headerText: "Total Folders", field: "foldersCount" },
              { headerText: "Space Used", field: "spaceUsed" },
              { headerText: "Last Activity Date", field: "lastActivityDate", type: "datetime", format: "dd/MM/yyyy" },
            ]}
            onPageChange={this.props.teamsTablePageChange} />
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <LineChartCard
            title={ReportStrings.teams.allTeams.teamsGrowthChart.title}
            description={ReportStrings.teams.allTeams.teamsGrowthChart.description.replaceAll('{months}', creationPeriod.toString())}
            chartOptions={creationChartData}
            colour="primary"
            seriesInfo={[{
              dataSource: creationChartData.data,
              xName: 'period',
              yName: 'count',
              name: 'Teams'
            },
            {
              dataSource: predictionData.data,
              xName: 'period',
              yName: 'count',
              name: 'Prediction'
            }]}
          />
        </div>
      </div>
    </div>;
  }

  private renderSectionToggles(): React.ReactElement {
    const { activeView } = this.state;
    return <div className="row">
      <div className="col-sm-12">
        <ul className="nav nav-pills nav-pills-primary">
          <li className="nav-item">
            <div className={`nav-link clickable ${(activeView == 0) ? 'active' : ''}`} onClick={() => this.tabClicked(0)}>
              All Teams
            </div>
          </li>
          <li className="nav-item">
            <div className={`nav-link clickable ${(activeView == 1) ? 'active' : ''}`} onClick={() => this.tabClicked(1)}>
              Orphaned Teams&nbsp;{this.renderOrphanedCountBadge()}
            </div>
          </li>
        </ul>
      </div>
    </div>;
  }

  private renderOrphanedCountBadge(): React.ReactElement | void {
    let orphanedTeamCount = this.props.orphanedTeams.length;
    if (orphanedTeamCount > 0)
      return <span className="badge badge-danger">{orphanedTeamCount}</span>
  }

  private formatVisibilityChartData(): void {
    const { orphanedOverviewInfo } = this.props;
    let formatted: any[] = [];
    if (orphanedOverviewInfo) {
      if (orphanedOverviewInfo.totalPrivateTeams)
        formatted.push({ x: 'Private Teams', y: orphanedOverviewInfo.totalPrivateTeams, r: '90%' });
      if (orphanedOverviewInfo.totalPublicTeams)
        formatted.push({ x: 'Public Teams', y: orphanedOverviewInfo.totalPublicTeams, r: '90%' });
    }
    this.setState({
      visibilityChartData: {
        ...this.state.visibilityChartData,
        data: formatted
      }
    });
  }

  // private renderCreationChangeDescription(): React.ReactElement {
  //   const data = this.state.creationChartData.data;
  //   let content = <span><i className="fa fa-info-circle"></i> (Not enough data for analysis).</span>
  //   if (data.length > 1) {
  //     const lastMonthCount = data[data.length - 2].count;
  //     const thisMonthCount = data[data.length - 1].count;
  //     if (thisMonthCount == lastMonthCount) {
  //       content = <span><span className="text-gray"><i className="fa fa-minus"></i> No change</span> in creations compared to last month.</span>;
  //     } else if (thisMonthCount > lastMonthCount) {
  //       const change: number = ((thisMonthCount - lastMonthCount) / lastMonthCount * 100);
  //       content = <span><span className="text-success"><i className="fa fa-long-arrow-up"></i> {isFinite(change) && `${change}%`} </span> increase in creations compared to last month.</span>;
  //     } else {
  //       const change: number = ((lastMonthCount - thisMonthCount) / lastMonthCount * 100);
  //       content = <span><span className="text-danger"><i className="fa fa-long-arrow-down"></i> {isFinite(change) && `${change}%`} </span> decrease in creations compared to last month.</span>;
  //     }
  //   }

  //   return <p className="card-category">{content}</p>;
  // }

  private formatCreationChartData(): void {
    //Loop through each of the previous months and get count
    const { creationData, creationPeriod } = this.props;
    let formatted: any[] = [];

    const monthNames = Utilities.getMonths();
    let today = new Date();
    let d: Date, month: string, year: number;

    for (let i = creationPeriod; i >= 0; i--) {

      d = new Date(today.getFullYear(), today.getMonth() - i, 1);
      month = monthNames[d.getMonth()];
      year = d.getFullYear();

      if (creationData && (creationData.length > 0)) {
        let countIndex = creationData.findIndex(i => ((i.month == month) && (i.year == year)));
        if (countIndex !== -1) {
          let storedValues = creationData[countIndex];
          formatted.push({
            period: `${d.toISOString()}`,
            count: storedValues.totalTeamsCreated
          });
        } else {
          formatted.push({
            period: `${d.toISOString()}`,
            count: 0
          });
        }
      }
    }

    this.setState({
      creationChartData: {
        ...this.state.creationChartData,
        data: formatted
      }
    });
  }

  private formatPredictionChartData(): void {
    //Loop through each of the previous months and get count
    const { predictionData, creationPeriod } = this.props;
    let formatted: any[] = [];

    const monthNames = Utilities.getMonths();
    let today = new Date();
    let d: Date, month: string, year: number;

    for (let i = creationPeriod; i >= 0; i--) {

      d = new Date(today.getFullYear(), today.getMonth() - i, 1);
      month = monthNames[d.getMonth()];
      year = d.getFullYear();

      if (predictionData && (predictionData.length > 0)) {        
        let countIndex = predictionData.findIndex(i => ((i.month == month) && (i.year == year)));
        if (countIndex !== -1) {
          let storedValues = predictionData[countIndex];
          formatted.push({
            period: `${d.toISOString()}`,
            count: storedValues.totalTeamsCreated
          });
        } else {
          formatted.push({
            period: `${d.toISOString()}`,
            count: 0
          });
        }
      }
    }

    this.setState({
      predictionData: {
        ...this.state.predictionData,
        data: formatted
      }
    });
  }


  private tabClicked = (tabIndex: number): void => {
    this.setState({
      activeView: tabIndex
    });
  }
}
