import * as React from 'react';
import { ReportSettings } from '../../../settings/Report/ReportSettings';

export interface ReRunProps {
}

export default class ReRunButton extends React.Component<ReRunProps> {
  constructor(props: ReRunProps) {
    super(props);

    this.state = {
    }
  }

  public render() {
    return (
      <div className="re-run-button-wrapper">
        <button className="re-run-button btn btn-rose btn-lg btn-round" title="Re-Run Report" onClick={this.onReRunClick}><i className="material-icons">restart_alt</i>&nbsp; Re-run report</button>
      </div>
    );
  }

  
  private onReRunClick = (): void => {
    window.open(ReportSettings.constants.productPageUrl,'_blank');
  }
}
