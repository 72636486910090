import * as React from 'react';
import ScoreCard from '../widgets/ScoreCard';

export interface IHeaderProps {
  title: string;
  description?: string;
  healthScore:number;
}

export default class Header extends React.Component<IHeaderProps> {

  public render() {
    const { title, description } = this.props;

    return (
      <div className={`report-header`}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 ml-auto mr-auto text-center">
              <ScoreCard title={title} description={description} score={this.props.healthScore} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
