import { Category, ChartComponent, ColumnSeries, DateTime, Inject, LineSeries, SeriesCollectionDirective, SeriesDirective, SeriesModel, Tooltip } from '@syncfusion/ej2-react-charts';
import * as React from 'react';
import { LineChartOptions } from '../../../models/Report/IChart';
import ClampLines from 'react-clamp-lines';
import uniqid from 'uniqid';

export interface ChartProps {
    title: string;
    description?: string;
    chartOptions: LineChartOptions;
    colour?: "success" | "warning" | "danger" | "primary" | "info" | "rose";
    badgeText?: string;
    badgeColour?: "success" | "warning" | "danger" | "primary" | "info" | "rose";
    seriesInfo: SeriesModel[];
}

export default class LineChartCard extends React.Component<ChartProps> {
    public render() {
        const { title, chartOptions, colour, badgeText, description } = this.props;

        return (
            <div className="card widget-card">
                <div className={`card-header card-chart card-header-${colour ? colour : "info"}`}>
                    <ChartComponent id="charts"
                        primaryXAxis={chartOptions.primaryXAxis}
                        primaryYAxis={chartOptions.primaryYAxis}
                        tooltip={chartOptions.tooltip}
                        legendSettings={chartOptions.legendSettings}>
                        <Inject services={[ColumnSeries, Tooltip, LineSeries, DateTime, Category]} />
                        {this.renderChartSeries()}
                    </ChartComponent>
                </div>
                <div className="card-body">
                    {badgeText && this.renderBadge()}
                    <h2 className="card-title">{title}</h2>
                    {description && <div className="category">
                        <ClampLines
                            id={`countercard_${uniqid()}`}
                            text={description}
                            lines={3}
                            ellipsis="..."
                            buttons={true}
                            className="clamped-text"
                            moreText={'Read more'}
                            lessText={'Read less'} />
                    </div>}
                </div>
                <div className="card-footer">
                    {this.props.children}
                </div>
            </div>
        );
    }

    private renderChartSeries(): React.ReactElement {
        const { seriesInfo } = this.props;
        return (<SeriesCollectionDirective> 
            {seriesInfo.map((series,i) => {
                return <SeriesDirective {...series} key={`series_${uniqid()}_${i}`}/>
            })}
        </SeriesCollectionDirective>);
    }

    private renderBadge(): React.ReactElement {
        const { badgeText, badgeColour } = this.props;
        return (<label className={`badge badge-${badgeColour ? badgeColour : "info"}`}>{badgeText}</label>);
    }
}
