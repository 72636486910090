import * as React from 'react';

export interface FooterProps {
}

export interface FooterState {
}

export default class Footer extends React.Component<FooterProps, FooterState> {
  constructor(props: FooterProps) {
    super(props);

    this.state = {
    };
  }

  public render() {
    return (
      <footer className="footer footer-white">
      <div className="container">
      <img className="footer-brand img-fluid" src="https://www.konsolute.com/wp-content/uploads/Konsolute-Microsoft-Gold-Partner-2-1.png" alt="Microsoft Gold Partner"/>
        <div className="pull-center">
         {/* <div className="row bold">
           <div className="col-12 col-md-6 col-lg-3">
             <a href="https://www.konsolute.com/products/"><h5 className="text-info">Products</h5></a>
             <ul className="list-group list-group-flush text-left">
               <li className="list-group-item"><a href="https://www.konsolute.com/products/reflekt/">Reflekt</a></li>
               <li className="list-group-item"><a href="https://www.konsolute.com/products/auto-classifier/">Auto Classifier</a></li>
               <li className="list-group-item"><a href="https://www.konsolute.com/products/onboard/">Onboard</a></li>
               <li className="list-group-item"><a href="https://www.konsolute.com/products/virtual-academy/">Virtual Academy</a></li>
               <li className="list-group-item"><a href="https://www.konsolute.com/products/iso-certified-qms-system/">ISO Certified QMS System</a></li>
               <li className="list-group-item"><a href="https://www.konsolute.com/products/kolumbus/">Kolumbus</a></li>
               <li className="list-group-item"><a href="https://www.konsolute.com/products/intranet/">Modern Intranet</a></li>
             </ul>
           </div>
           <div className="col-12 col-md-6 col-lg-3">
           <a href="https://www.konsolute.coms/services/"><h5 className="text-info">Services</h5></a>
             <ul className="list-group list-group-flush text-left">
             <li className="list-group-item"><a href="https://www.konsolute.com/services/cloud-strategy-and-readiness/">Cloud Strategy &amp; Readiness</a></li>
             <li className="list-group-item"><a href="https://www.konsolute.com/services/cloud-migration-services/">Cloud Migration</a></li>
             <li className="list-group-item"><a href="https://www.konsolute.com/services/cloud-modernisation/">Cloud Modernisation</a></li>
             <li className="list-group-item"><a href="https://www.konsolute.com/services/security-compliance/">Security &amp; Compliance</a></li>
             </ul>
           </div>
           <div className="col-12 col-md-6 col-lg-3">
           <a href="https://www.konsolute.com/products/"><h5 className="text-info">Company</h5></a>
             <ul className="list-group list-group-flush text-left">
               <li className="list-group-item"><a href="https://www.konsolute.com/about/">About</a></li>
               <li className="list-group-item"><a href="https://www.konsolute.com/sitemap.xml">Sitemap</a></li>
             </ul>
           </div>
           <div className="col-12 col-md-6 col-lg-3">
           <a href="https://www.konsolute.com/products/"><h5 className="text-info">Help Centre</h5></a>
             <ul className="list-group list-group-flush text-left">
               <li className="list-group-item"><a href="https://www.konsolute.com/support/">Support</a></li>
               <li className="list-group-item"><a href="https://www.konsolute.com/cookies-policy/">Cookies</a></li>
             </ul>
           </div>
         </div> */}
        </div>
        <ul className="social-buttons float-right">
          {/* <li>
            <a href="https://twitter.com/konsolute" target="_blank" className="btn btn-just-icon btn-link btn-twitter">
              <i className="fa fa-twitter"></i>
            </a>
          </li>
          <li>
            <a href="https://www.instagram.com/konsolute" target="_blank" className="btn btn-just-icon btn-link btn-instagram">
              <i className="fa fa-instagram"></i>
            </a>
          </li> */}
          <li> 
            <a href="https://www.linkedin.com/company/konsolute" target="_blank" className="btn btn-just-icon btn-link btn-linkedin" rel="noopener noreferrer">
              <i className="fa fa-linkedin"></i>
            </a>
          </li>
        </ul>
      </div>
    </footer>);
  }
}
