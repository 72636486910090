import React, { Component } from 'react';
import { Route, Redirect } from 'react-router';
import { Layout } from './components/Layout';

import './custom.css'
import { ProductInfo } from './views/ProductInfo';
import { ProductForm } from './views/ProductForm';
import TenancyReport from './views/Report/TenancyReport';
import ProductFormComplete from './views/FormComplete';
import { IProductService } from "./services/IProductService";
import { ProductService } from "./services/ProductService";
import { ISettingsService } from "./services/ISettingsService";
import { SettingsService } from "./services/SettingsService";
import { IReportService } from "./services/IReportService";
import { ReportService } from "./services/ReportService";
import AuthProvider from './auth/AuthProvider';
import { BrowserRouter } from 'react-router-dom';
import Loading from './components/Loading';

var productService: IProductService = new ProductService();
var settingsService: ISettingsService = new SettingsService();
var reportService: IReportService = new ReportService();

interface IAppState {
  loading: boolean;
  authProvider?: AuthProvider;
}

export default class App extends Component<{}, IAppState> {
  static displayName = App.name;

  constructor(props: any) {
    super(props);

    this.state = {
      loading: true
    };
  }

  async componentDidMount() {
    var authSettings = await settingsService.getAuthSettings();
    this.setState({
      loading: false,
      authProvider: new AuthProvider(authSettings)
    });
  }

  public render() {
    return <Layout authProvider={this.state.authProvider}>
      {
        this.state.loading ?
          <Loading /> :
          <>
            <Route exact path='/' component={(props: any) => <ProductInfo {...props} productService={productService} />} />
            <Route path='/form'
              component={(props: any) => <ProductForm {...props} productService={productService} authProvider={this.state.authProvider} />} />
            <Route path='/complete'
              component={(props: any) => <ProductFormComplete {...props} authProvider={this.state.authProvider} />} />
            <Route path='/healthreport' component={(props: any) => <TenancyReport {...props} authProvider={this.state.authProvider} reportService={reportService}/>} />
          </>}
    </Layout>;
  }
}
