import * as React from 'react';
import './Loading.css';

export interface LoadingProps {
}

export interface LoadingState {
}

export default class Loading extends React.Component<LoadingProps, LoadingState> {
    constructor(props: LoadingProps) {
        super(props);

        this.state = {
        }
    }

    public render() {
        return (
            <div className="page-header section-white">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 ml-auto mr-auto text-center">                            
                            <div className="loading">
                                <img src="./assets/img/konsolute-flag.png" alt="Konsolute logo loading" className="img-fluid"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
