import { isIE, GRAPH_SCOPES } from "./AuthUtil";

import { UserAgentApplication, AuthResponse, AuthenticationParameters } from "msal";
import { IAuthSettings } from "../models/IAuthSettings";

//const useRedirectFlow = true;

export default class AuthProvider {
    private msalApp: UserAgentApplication;
    private apiScope: any;
    constructor(authSettings: IAuthSettings) {
        
        this.apiScope = {
            scopes: [
            `api://${authSettings.clientId}/user_impersonation`
        ]};

        var baseUrl = `${window.location.protocol} + "//" + ${window.location.host}`;
        this.msalApp = new UserAgentApplication({
            auth: {
                clientId: authSettings.clientId,
                authority: `${authSettings.instance}common/`,
                validateAuthority: false,
                postLogoutRedirectUri: `${baseUrl}${authSettings.postLogoutRedirectUri}`,
                navigateToLoginRequestUrl: false
            },
            cache: {
                cacheLocation: "sessionStorage",
                storeAuthStateInCookie: isIE()
            }
        });

        this.msalApp.handleRedirectCallback((error: any) => {
            if (error) {
                const errorMessage = error.errorMessage ? error.errorMessage : "Unable to acquire access token.";
                console.log(errorMessage);
            }
        });
    }

    ensureLogin(scopes: string[]) {
        const account = this.msalApp.getAccount();

        if(!account) {
            let authScopes: AuthenticationParameters = {
                scopes: [
                    GRAPH_SCOPES.OPENID,
                    GRAPH_SCOPES.PROFILE,
                    GRAPH_SCOPES.USER_READ,
                    ...scopes
                ]
            };

            return this.msalApp.loginRedirect(authScopes);
        }
    }

    async ensureLoginPopup(scopes: string[]) {
        const account = this.msalApp.getAccount();
        
        if(!account) {
            let authScopes: AuthenticationParameters = {
                scopes: [
                    GRAPH_SCOPES.OPENID,
                    GRAPH_SCOPES.PROFILE,
                    GRAPH_SCOPES.USER_READ,
                    ...scopes
                ]
            };

            //return this.msalApp.loginRedirect(authScopes);
            return await this.msalApp.loginPopup(authScopes);
        }
    }

    //ensureLogin(scopes: any) {
    //    const account = this.msalApp.getAccount();
    //    if (!account) {
    //        return this.msalApp.loginRedirect(GRAPH_REQUESTS.LOGIN);
    //    }
    //}

    async acquireToken(scopes: any): Promise<AuthResponse> {
        return await this.msalApp.acquireTokenSilent(scopes);
        // return msalApp.acquireTokenSilent(scopes).catch((error: any) => {
        //     // Call acquireTokenPopup (popup window) in case of acquireTokenSilent failure
        //     // due to consent or interaction required ONLY
        //     if (requiresInteraction(error.errorCode)) {
        //         return useRedirectFlow
        //             ? msalApp.acquireTokenRedirect(scopes)
        //             : msalApp.acquireTokenPopup(scopes);
        //     } else {
        //         console.error('Non-interactive error:', error.errorCode)
        //     }
        // });
    }

    async getApiAccessToken(): Promise<string> {
        var authResponse = await this.acquireToken(this.apiScope);
        return authResponse.accessToken;
    }

    getAccount() {
        return this.msalApp.getAccount();
    }

    getApiScope(){
        return this.apiScope.scopes;
    }
}