import * as React from 'react';
import { AccumulationChartComponent, AccumulationDataLabel, AccumulationLegend, AccumulationSeriesCollectionDirective, AccumulationSeriesDirective, AccumulationTooltip, Category, ChartComponent, ColumnSeries, Inject, LineSeries, PieSeries, SeriesCollectionDirective, SeriesDirective, Tooltip } from '@syncfusion/ej2-react-charts';
import { PieChartData } from '../../../models/Report/IChart';
import ClampLines from 'react-clamp-lines';
import uniqid from 'uniqid';

export interface ChartProps {
    title: string;
    description?: string;
    chartOptions: PieChartData;
    colour?: "success" | "warning" | "danger" | "primary" | "info" | "rose";
    badgeText?: string;
    badgeColour?: "success" | "warning" | "danger" | "primary" | "info" | "rose";
}

export default class PieChartCard extends React.Component<ChartProps> {
    pieRef = React.createRef<AccumulationChartComponent>();

    constructor(props: ChartProps) {
        super(props);
    }

    public render() {
        const { title, colour, badgeText, description } = this.props;

        return (
            <div className="card card-chart widget-card">
                <div className={`card-header card-header-${colour ? colour : "info"}`}>
                    <h4 className="card-title">{title}</h4>
                    {description && <div className="category">
                        <ClampLines
                            id={`countercard_${uniqid()}`}
                            text={description}
                            lines={3}
                            ellipsis="..."
                            buttons={true}
                            className="clamped-text"
                            moreText={'Read more'}
                            lessText={'Read less'} />
                    </div>}
                </div>
                <div className="card-body">
                    {this.renderChart()}
                </div>
                <div className="card-footer">
                    <div className="row">
                        <div className="col-md-12">
                            {badgeText && this.renderBadge()}
                            {this.props.children}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    private renderChart(): React.ReactElement {
        const { chartOptions } = this.props;
        if (chartOptions.data && (chartOptions.data.length > 0)) {
            return <div className="ct-chart">
                <AccumulationChartComponent id='pie-chart' ref={this.pieRef}
                    legendSettings={{
                        visible: true
                    }}
                    enableSmartLabels={true}
                    enableAnimation={true}
                    tooltip={{ enable: true }}
                >
                    <Inject services={[AccumulationLegend, PieSeries, AccumulationDataLabel, AccumulationTooltip]} />
                    <AccumulationSeriesCollectionDirective>
                        <AccumulationSeriesDirective dataSource={chartOptions.data} xName='x' yName='y' innerRadius='50%'
                            dataLabel={chartOptions.dataLabel}
                            radius='r'
                        >
                        </AccumulationSeriesDirective>
                    </AccumulationSeriesCollectionDirective>
                </AccumulationChartComponent>
            </div>;
        } else {
            return <div className="text-center"><h4 className="text-gray">(No data available)</h4></div>
        }
    }

    private renderBadge(): React.ReactElement {
        const { badgeText, badgeColour } = this.props;
        return (<label className={`badge badge-${badgeColour ? badgeColour : "info"}`}>{badgeText}</label>);
    }
}
