import * as React from 'react';
import Spinner from './Spinner';

export interface ICounterProps {
  colour?: "success" | "warning" | "danger" | "primary" | "info" | "rose";
  title: string;
  value?: string | number;
  icon?: string;
  loading?:boolean;
}

export interface ICounterState {
}

export default class CounterCard extends React.Component<ICounterProps, ICounterState> {
  constructor(props: ICounterProps) {
    super(props);

    this.state = {
    }
  }

  public render() {
    const { title, colour, icon } = this.props;

    return (
      <div className="card card-stats widget-card">
        <div className={`card-header card-header-${colour ? colour : 'info'} ${icon ? 'card-header-icon' : ''}`}>
          {icon && <div className="card-icon">
            <i className="material-icons">{icon}</i>
          </div>}
          <p className="card-category">{title}</p>
          <h3 className="card-title">{this.renderValue()}</h3>
        </div>
        <div className="card-footer equal-card-height">
          {this.props.children}
        </div>
      </div>
    );
  }

  private renderValue(){
    const {loading, value } = this.props;
    if(loading){
      return <Spinner />;
    }

    if(value){  
      return value;
    }else{
      if(typeof(value) == 'number'){
        return 0;
      }else{
        return 'N/A';
      }
    }
  }
}
