import { ValidateFunction } from "ajv";
import { TenantService } from "./TenantService";
import AuthProvider from "../auth/AuthProvider";

export class ValidationService {
    public static siteCollectionFormat: string = "";
    public static async init(authProvider: AuthProvider, validators: string[]): Promise<void> {
        validators.forEach(async val => {
            switch(val) {
                case "sitecollection":
                    const tenantName = await TenantService.getTenantName(authProvider);
                    this.siteCollectionFormat = `https:\/\/${tenantName}.sharepoint.com\/sites\/`;
                    break;
            }
        });
    }

    public static validate(
        data: any,
        dataPath?: string,
        parentData?: object | Array<any>,
        parentDataProperty?: string | number,
        rootData?: object | Array<any>): boolean {
            return true;
    }

    public static validator(validator: string) : string {
        switch(validator) {
            case "sitecollection":
                return `^(${ValidationService.siteCollectionFormat}){1}[a-zA-Z0-9\-]+$`;
            default:
                return ``;
        }
    }
}

function siteCollectionValidator(this: any,  
    schema: any,
    data?: string,
    parentSchema?: object | Array<any>,
    parentDataProperty?: string | number,
    rootData?: object | Array<any>): boolean {
        if(data) {
            let regex = new RegExp(`^(${ValidationService.siteCollectionFormat}){1}[a-zA-Z0-9\-]+$`);
            const isValid = regex.test(data);
            if(!isValid) {
                this.errors = [
                    {
                        data: data,
                        dataPath: "siteurl",
                        keyword: "sitecollection",
                        message: "Should match sharepoint online url",
                        params: {
                            keyword: "sitecollection"
                        },
                        parentSchema: parentSchema,
                        schema: schema
                    }
                ];
            }

            return isValid;
        }

        return false;
}