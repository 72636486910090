import * as React from 'react';
import { RouteComponentProps } from "react-router";
import AuthProvider from "../auth/AuthProvider";
import { IProductService } from '../services/IProductService';
import { IProductInfo } from '../models/ProductInfo';
import { ItemStorage } from '../itemstorage';
import { Constants } from '../constants';
import Loading from '../components/Loading';
import Alert from '../components/Alert';

export interface FormProps extends RouteComponentProps<{}> {
  authProvider: AuthProvider;
}

export interface FormState {
  loading: boolean;
  productInfo?: IProductInfo;
  errors?: string[];
}

export default class ProductFormComplete extends React.Component<FormProps, FormState> {
  constructor(props: FormProps) {
    super(props);

    this.state = {
      loading: true
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    var productInfo = ItemStorage.getStorageItem(Constants.productInfo) as IProductInfo;
    var scopes: string[] = [];

    productInfo.permissions.filter(x => x.requiresConsent).forEach((item) => {
      scopes.push(item.scope);
    });

    this.props.authProvider.ensureLogin(scopes);
    if (productInfo) {
      this.setState({
        loading: false,
        productInfo: productInfo
      });
    } else {
      console.log('Product not specified, redirecting to products page...');
      window.location.href = 'https://www.konsolute.com/products';
    }
  }

  public render() {
    return <>
      {
        this.state.loading ?
          <Loading /> :
          this.renderBody()
      }

    </>;
  }

  private renderBody(): React.ReactFragment {
    return (<>
      <div className="page-header clear-filter white-bg">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <img className="img-fluid" src={this.state.productInfo?.headerImage} />
            </div>
            <div className="col-md-4">
              <div className="brand">
                <h1 className="text-dark"><strong>{this.state.productInfo?.displayName}</strong> is now being requested</h1>
                <h3 className="title konsblue">You'll get an email notification (to your submitted email) when your product is ready</h3>
                <a className="btn btn-info btn-xl btn-round btn-install" href="https://www.konsolute.com/products">Try another product</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section section-dark">
        <div className="container">
          <div className="section text-center">
            <div className="row">
              <div className="col-12">
                <a href={this.state.productInfo?.supportLink} className="btn btn-round btn-default btn-lg">
                  <i className="material-icons">library_books</i>&nbsp;Knowledge Base
                  </a>
                <a href="mailto:support@konsolute.com" className="btn btn-round btn-default btn-lg">
                  <i className="material-icons">email</i>&nbsp;Contact Us</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {this.renderErrors()}
    </>);
  }

  private renderErrors(): React.ReactNode | void {
    if (this.state.errors && (this.state.errors.length > 0)) {
      return this.state.errors.map((error, i) => {
        return <Alert message={error} />;
      });
    }
  }

}
