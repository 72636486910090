import { ITeamsCreatedSummary, IOrphanedOverview, IOverviewReport, ITeamsReport, IExternalAppsSummary, IChannelsReport } from "../models/Report/ITabModels";
import { IOrphanedTeam } from "../models/Report/ITeam";
import { IReportService } from "./IReportService";

export class ReportService implements IReportService {
    async getOverviewData(reportId: string, accessToken: string): Promise<IOverviewReport> {
        var url = `/api/report/teams/${reportId}/overview`;
        const options = {
            method: "GET",
            headers: {
                'Authorization': `bearer ${accessToken}`,
                'Content-Type': 'application/json'
            },
        };

        var response = await fetch(url, options);
        return await response.json() as IOverviewReport;
    }

    async getOrphanedTeams(reportId: string, accessToken: string): Promise<IOrphanedTeam[]> {
        var url = `/api/report/teams/${reportId}/orphaned`;
        const options = {
            method: "GET",
            headers: {
                'Authorization': `bearer ${accessToken}`,
                'Content-Type': 'application/json'
            },
        };

        var response = await fetch(url, options);
        let res = await response.json() as IOrphanedTeam[];
        return res;
    }

    async getAllTeams(reportId: string, accessToken: string, page: number, pageSize: number): Promise<ITeamsReport> {
        var url = `/api/report/teams/${reportId}?page=${page}&pageSize=${pageSize}`;
        const options = {
            method: "GET",
            headers: {
                'Authorization': `bearer ${accessToken}`,
                'Content-Type': 'application/json'
            },
        };

        var response = await fetch(url, options);
        return await response.json() as ITeamsReport;
    }

    async getOrphanedOverviewData(reportId: string, accessToken: string): Promise<IOrphanedOverview> {
        var url = `/api/report/teams/${reportId}/orphaned/overview`;
        const options = {
            method: "GET",
            headers: {
                'Authorization': `bearer ${accessToken}`,
                'Content-Type': 'application/json'
            },
        };

        var response = await fetch(url, options);
        return await response.json() as IOrphanedOverview;
    }

    async getTeamsCreated(reportId: string, months: number, accessToken: string): Promise<ITeamsCreatedSummary[]> {
        var url = `/api/report/teams/${reportId}/monthly/${months}`;
        const options = {
            method: "GET",
            headers: {
                'Authorization': `bearer ${accessToken}`,
                'Content-Type': 'application/json'
            },
        };

        var response = await fetch(url, options);
        return await response.json() as ITeamsCreatedSummary[];
    }

    async getGrowthPrediction(reportId: string, months: number, accessToken: string): Promise<ITeamsCreatedSummary[]> {
        var url = `/api/report/teams/${reportId}/growthprediction?months=${months}`;
        const options = {
            method: "GET",
            headers: {
                'Authorization': `bearer ${accessToken}`,
                'Content-Type': 'application/json'
            },
        };

        var response = await fetch(url, options);
        return await response.json() as ITeamsCreatedSummary[];
    }

    async getExternalAppsSummary(reportId: string, top: number, accessToken: string): Promise<IExternalAppsSummary> {
        var url = `/api/report/teams/${reportId}/externalapps/summary?topAppsCount=${top}`;
        const options = {
            method: "GET",
            headers: {
                'Authorization': `bearer ${accessToken}`,
                'Content-Type': 'application/json'
            },
        };

        var response = await fetch(url, options);
        return await response.json() as IExternalAppsSummary;
    }

    async getTeamsWithExternalApps(reportId: string, accessToken: string, page: number, pageSize: number): Promise<ITeamsReport> {
        var url = `/api/report/teams/${reportId}/externalapps/teams?page=${page}&pageSize=${pageSize}`;
        const options = {
            method: "GET",
            headers: {
                'Authorization': `bearer ${accessToken}`,
                'Content-Type': 'application/json'
            },
        };

        var response = await fetch(url, options);
        return await response.json() as ITeamsReport;
    }

    async getChannelsSummary(reportId: string, months: number, accessToken: string): Promise<IChannelsReport> {
        var url = `/api/report/teams/${reportId}/channels/summary?months=${months}`;
        const options = {
            method: "GET",
            headers: {
                'Authorization': `bearer ${accessToken}`,
                'Content-Type': 'application/json'
            },
        };

        var response = await fetch(url, options);
        return await response.json() as IChannelsReport;
    }
}