import { ISettingsService } from "./ISettingsService";
import { IAuthSettings } from "../models/IAuthSettings";

export class SettingsService implements ISettingsService {
    public async getAuthSettings(): Promise<IAuthSettings> {
        var url = `/api/settings`;
        var response = await fetch(url);

        return await response.json() as IAuthSettings;
    }
}