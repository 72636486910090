import * as React from 'react';
import IPillOption from '../../../models/Report/IPillOptions';

export interface IPillProps {
    options: IPillOption[];
    pillClicked(index: number): void;
    currentTab: number;
}

export interface IPillState {
}

export default class NavPills extends React.Component<IPillProps, IPillState> {
    constructor(props: IPillProps) {
        super(props);

        this.state = {
        }
    }

    public render() {
        return (
            <div className="report-section-tabs">
                <ul className="nav nav-pills nav-pills-icons justify-content-center nav-pills-info" role="tablist">
                    {this.renderPills()}
                </ul>
            </div>
        );
    }

    private renderPills(): React.ReactElement[] {
        const { options, currentTab, pillClicked } = this.props;

        return options.map((option, index) => <li className="nav-item" key={`navPill_${index}`}>
            <span className={`nav-link clickable ${(currentTab == index) ? 'active' : ''}`} onClick={() => pillClicked(index)} role="tab" data-toggle="tab">
                {(option.notificationCount && (option.notificationCount > 0)) && <span className="badge badge-danger notification">{option.notificationCount}</span>}
                <i className="material-icons">{option.icon}</i>
                {option.title}
            </span>
        </li>
        );
    }

}
