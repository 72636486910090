
import { IProductPrerequisite } from "../models/ProductInfo";
import AuthProvider from "../auth/AuthProvider";
import { SharePointService } from "./SharePointService";
import { TenantService } from "./TenantService";

export class PrerequisitesService {
    private authProvider: AuthProvider;
    private prerequisites: IProductPrerequisite[];
    constructor(authProvider: AuthProvider, prerequisites: IProductPrerequisite[]) {
        this.authProvider = authProvider;
        this.prerequisites = prerequisites;
    }

    public async validate(): Promise<IPrerequisitesValidationResult[]> {
        const tenantName = await TenantService.getTenantName(this.authProvider);
        let result: IPrerequisitesValidationResult[] = [];
        for(let i=0;i<this.prerequisites.length;i++) {
            const item = this.prerequisites[i];
            switch(item.name) {
                case "appcatalog":
                    try {
                        const appCatalogExists = await SharePointService.checkAppCatalogExists(this.authProvider, tenantName);
                        if(!appCatalogExists) {
                            result.push({
                                isValid: false,
                                errorMessage: "App catalog is not provisioned"
                            });
                        } else {
                            result.push({
                                isValid: true,
                                errorMessage: ""
                            });
                        }
                    } catch(err) {
                        result.push({
                            isValid: false,
                            errorMessage: "Cannot reach app catalog"
                        });
                    }

                    break;
            }
        }
        
        return result;
    }
}

export interface IPrerequisitesValidationResult {
    isValid: boolean;
    errorMessage: string;
}