import * as React from 'react';
import uniqid from 'uniqid';

export interface IPaginationProps {
    pageCount: number;
    currentPage: number;
    onPageChange?(pageNumber: number): void;
}

export interface IPaginationState {
}

export default class Pagination extends React.Component<IPaginationProps, IPaginationState> {
    constructor(props: IPaginationProps) {
        super(props);

        this.state = {
        }
    }

    public render() {
        return (
            <div className="row">
                <div className="col-sm-12">
                    <div className="pagination-wrapper">
                        {this.renderPageSelectors()}

                    </div>
                </div>
            </div>
        );
    }

    private renderPageSelectors(): React.ReactElement {
        const { pageCount, currentPage, onPageChange } = this.props;
        let pageSelectors: React.ReactElement[] = [];
        const pages = this.getPages(currentPage, pageCount);
        for (let i = 0; i < pages.length; i++) {
            const page = pages[i];
            pageSelectors.push(<li key={`paging_control_${uniqid()}`} className={`page-item${page == currentPage ? " active" : ""}`} onClick={() => { onPageChange && onPageChange(page) }}>
                <span className="page-link">{page}<span className="sr-only">(current)</span></span>
            </li>);
        }

        return <ul className="pagination pagination-primary justify-content-center">
            <li className={`page-item ${(currentPage <= 1) ? 'disabled' : ''}`} onClick={() => { onPageChange && onPageChange(currentPage - 1) }}>
                <span className="page-link">Previous</span>
            </li>
            {pageSelectors}
            <li className={`page-item ${(currentPage >= pageCount) ? 'disabled' : ''}`} onClick={() => { onPageChange && onPageChange(currentPage + 1) }}>
                <span className="page-link">Next</span>
            </li>
        </ul>;
    }

    private getPages(currentPage: number, totalPages: number) {
        const pagesToDisplay: number = 3;
        let pages: number[] = [];

        let isFirst = currentPage == 1;
        let isLast = currentPage == totalPages;

        if (isFirst && isLast) {
            pages.push(currentPage);
        } else if (isFirst) {
            pages.push(currentPage);
            for (let i = currentPage + 1, j = 1; i <= totalPages; i++, j++) {
                if (i <= totalPages && j != pagesToDisplay) {
                    pages.push(i);
                }
                else {
                    break;
                }
            }
        } else if (isLast) {
            for (let i = currentPage - 1, j = 1; i >= 1; i--, j++) {
                if (i >= 1 && j != pagesToDisplay) {
                    pages.push(i);
                } else {
                    break;
                }
            }

            pages.push(currentPage);
        } else {
            pages.push(currentPage - 1);
            pages.push(currentPage);
            pages.push(currentPage + 1);
        }

        return pages.sort((a,b) => a-b);
    }
}
