import { AccumulationChartComponent, AccumulationSeriesCollectionDirective, AccumulationSeriesDirective } from '@syncfusion/ej2-react-charts';
import * as React from 'react';
import { ReportSettings, ReportStrings } from '../../../settings/Report/ReportSettings';

export interface IScoreSectionProps {
  title: string;
  description?: string;
  score: number;
}

export default class ScoreCard extends React.Component<IScoreSectionProps> {
  scorePieChart = React.createRef<AccumulationChartComponent>();

  public render() {
    const { score, title, description } = this.props;

    return (
      <div className="card bg-dark text-white scorecard">
        <div className="card-body">
          <div className="row justify-content-center">
            <div className="col-sm-12 col-md-12 col-lg-10 col-xl-8">
              <h1 className="text-center">{title}</h1>
              {description && <h5>{description}</h5>}
            </div>
          </div>
          <div className="row justify-content-center align-items-center scorecard-body">
            <div className="col-sm-12 col-md-12 col-lg-5 col-xl-4 text-sm-center text-lg-left">
              <h3><strong>{ReportStrings.scoreCard.scoreLabel}</strong></h3>
              {this.renderScore(score)}
              {this.renderBlurb(score)}
            </div>
            <div className="col-sm-12 col-md-12 col-lg-5 col-xl-3 text-center">
              {this.renderChart(score)}
            </div>
          </div>
          <hr />
          {this.renderFooter()}
        </div>
      </div>
    );
  }

  private renderScore(score: number): React.ReactElement {
    return <h2 className={`${this.getTextColour(score)} score`}><strong>{score}</strong>%&nbsp;{this.getIcon(score)}</h2>
  }

  private renderChart(score: number): React.ReactElement {

    const delta = 100 - score;

    const data = [
      { x: 'Compliant', y: score, r: '90%' }, { x: 'Issues', y: delta, r: '50%' },
    ];

    return <div className="ct-chart">
      <AccumulationChartComponent id="scorecard-chart" background="transparent" ref={this.scorePieChart} height="200px">
        <AccumulationSeriesCollectionDirective>
          <AccumulationSeriesDirective dataSource={data} xName='x' yName='y' radius='90%' innerRadius="40%" palettes={["#FFFFFF", "#3e454c"]} />
        </AccumulationSeriesCollectionDirective>
      </AccumulationChartComponent>
    </div>;
  }

  private renderBlurb(score: number): React.ReactElement {
    if (score > 70) {
      return <h4 className="text-gray">{ReportStrings.scoreCard.good}</h4>;
    } else if (score > 40) {
      return <h4 className="text-gray">{ReportStrings.scoreCard.ok}</h4>;
    } else {
      return <h4 className="text-gray">{ReportStrings.scoreCard.bad}</h4>;
    }
  }

  private getIcon(score: number): React.ReactElement {
    if (score > 70) {
      return <i className="material-icons">thumb_up_off_alt</i>;
    } else if (score > 40) {
      return <i className="material-icons">thumbs_up_down</i>;
    } else {
      return <i className="material-icons">thumb_down_off_alt</i>;
    }
  }

  private getTextColour(score: number): string {
    if (score > 70) {
      return "text-success";
    } else if (score > 40) {
      return "text-warning";
    } else {
      return "text-danger";
    }
  }

  private renderFooter(): React.ReactElement {
    return (<div className="row text-center">
      <div className="col-sm-12">
        <h4>
          {ReportStrings.scoreCard.reflektBlurb}&nbsp;
        <a href={ReportSettings.constants.reflektUrl} className="btn btn-info btn-round btn-lg" target="_blank" rel="noopener noreferrer" title="Link to Reflekt product page">
            See how
        </a>
        </h4>
      </div>
    </div>);
  }
}
