
export class ItemStorage {
    public static setStorageItem(key: string, value: any) {
        sessionStorage.setItem(key, JSON.stringify(value));
    }

    public static setStorageItemAsString(key: string, value: any) {
        sessionStorage.setItem(key, value);
    }

    public static getStorageItem(key: string): any {
         var value = sessionStorage.getItem(key);
         if(value) {
             return JSON.parse(value);
         }
    }

    public static getStorageItemAsString(key: string): any {
        var value = sessionStorage.getItem(key);
        return value;
   }

    public static clearStorageItem(key: string) {
        sessionStorage.removeItem(key);
    }

    public static clear() {
        sessionStorage.clear();
    }
}