export const ReportSettings = {
    constants: {
        reportId: 'reportId', 
        listItemLimit: 10,
        reflektUrl: 'https://www.konsolute.com/products/reflekt',
        productPageUrl: '/?product=reporting&uid=1234',
    },
    scoreWeightings: { //Out of 1 (100%)
        orphanedTeams: 0.12,
        duplicateTeams: 0.15,
        teamsWithGuests: 0.35,
        externalApps: 0.28
    }
}

export const ReportStrings = {
    headerTitle: 'Teams Health Check Report ',
    headerDescription: 'The results are in! We\'re excited to share the output of the analysis that we have run against your tenancy. To give you\'re an overview, we\'ve analysed your Microsoft Teams, Channels, associated Apps and the storage. ',
    scoreCard: {
        scoreLabel: 'Your health score',
        good: 'Congratulations! Based upon a benchmark, the analysis indicates that you\'re doing great.',
        ok: 'Based upon a benchmark, the analysis indicates that there is room for improvement. The report provides you with an overview of what these metrics mean and how you could improve your score.',
        bad: 'Based upon a benchmark, the analysis indicates that perhaps you may need to look into things. The report provides you with an overview of what these metrics mean and how you could improve your score.',
        reflektBlurb: 'Konsolute Reflekt can help you govern, manage and automate your Microsoft 365 platform'
    },
    overview: {
        blurb: 'Here you\'ll find an overview of our findings. We\'ve categorised and highlighted the results.',
        teamsCounter: {
            title: 'Number of teams',
            description: 'Indicates the total number of Teams that your organisation has created. It\'s important to ensure that you manage your Teams lifecycle to reduce site sprawl.',
        },
        guestsCounter: {
            title: 'Teams with guests',
            description: 'Indicates the total number of Teams who have invited Guest (external to your organisation). Inviting guests\' users into Teams is a fantastic way to collaborate. However, a lack of governance can lead to sensitive information being exposed to users external to your organisation.',
        },
        storageCounter: {
            title: 'Total storage used',
            description: 'Indicates the total storage utilised by your Teams. Teams is a fantastic way to easily collaborate with your colleagues and contains a wealth of information. Are you making use of this data? Does your data comply to regulatory and compliance guidelines?',
        },
        duplicateCounter: {
            title: 'Duplicate teams',
            description: 'Indicates the total number of duplicate Teams within your organisation which share the same name. Discoverability of Teams is incredibly important to ensure that users are aware of other projects / teams within your organisation.',
          },
        totalGuestsCounter: {
            title: 'Total guests',
            description: 'Indicates the total number of Teams who have invited Guest (external to your organisation). Inviting guests\' users into Teams is a fantastic way to collaborate. However, a lack of governance can lead to sensitive information being exposed to users external to your organisation.',
        },
        appsCounter: {
            title: 'Total apps',
            description: 'Indicates the number of third-party (i.e. non-Microsoft) apps that are being used across your Microsoft Teams. Apps are a great way to streamlines processes within Teams and collaborate with your favourite third-party applications. However, without the necessary diligence your organisational data might be stored externally and may be in breach of your organisational guidelines.',
        },
        orphanedTeamsList: {
            title: 'Orphaned teams',
            description: 'Teams sometimes suffer from loneliness too. This list shows you all the Teams without Owners. It\'s important that your Team has a purpose in life and has at least an Owner who is responsible for managing / governing the Team. If you\'re not using, let\'s archive it.',
        },
        allTeamsList: {
            title: 'All teams',
            description: 'Here you\'ll find a detailed view of the Teams analysis. You\'ll see all your Teams and the associated information such as when it was created'
        },
        visibilityChart: {
            title: 'Teams by Visibility',
            description: 'This shows you your Teams by their visibility. Teams enables users to specify the visibility (Public or Private) and most often end-users don\'t quite understand the differences between the two. Private teams can lead to information being siloed away from the wider organisation. Information is one of the biggest assets to any organisation. Are your Private teams too private? On the flip side, Public Teams enable users to freely join/leave, collaborate with colleagues and discovery information. Sometime, Public Teams can pose issues, when sensitive information is visible across the organisation.',
        },
    },
    teams: {
        title: '',
        blurb: '',
        allTeams: {
            title: 'All Teams',
            teamsCounter: {
                title: 'Total number of teams',
                description: 'Indicates the total number of Teams that your organisation has created. It\'s important to ensure that you manage your Teams lifecycle to reduce site sprawl.'
            },
            storageCounter: {
                title: 'Storage consumed',
                description: 'Indicates the total storage utilised by your Teams. Teams is a fantastic way to easily collaborate with your colleagues and contains a wealth of information. Are you making use of this data? Does your data comply to regulatory and compliance guidelines?'
            },
            documentsCounter: {
                title: 'Documents held',
                description: 'Indicates the total number of documents across your Microsoft Teams. How are you managing your documents? Are you making the most of your organisational data? Does your data adhere to regulatory and compliance guidelines?'
            },
            foldersCounter: {
                title: 'Folders used',
                description: 'Indicates the total number of folders across your Teams. Folders provide a mechanism of structuring information so that it can be easily found. An excessive use of folders can pose issues when finding / search for information.'
            },
            teamsList: {
                title: 'All teams',
                description: 'Here you\'ll find a detailed view of the Teams analysis. You\'ll see all your Teams and the associated information such as number of numbers of owner, members, and guests to mention a few.'
            },
            teamsGrowthChart: {
                title: 'Teams growth',
                description: 'The chart shows your Teams growth over the last {months} months. Based upon your data we\'ve calculated your projected growth for the next {months} months.'
            }
        },
        orphanedTeams: {
            title: 'Orphaned Team Highlights',
            teamsCounter: {
                title: 'Orphaned Teams',
                description: 'Indicates the total number of orphaned Teams. Used Orphaned Teams should be archived in accordance your organisational retention guidelines. If the Team is being actively being used, at least 1 owner should be specified.'
            },
            storageCounter: {
                title: 'Storage consumed',
                description: 'Indicates the total storage utilised by the orphaned Teams.'
            },
            documentsCounter: {
                title: 'Documents held',
                description: 'Indicates the total number of documents held within the orphaned Teams.'
            },
            foldersCounter: {
                title: 'Folders used',
                description: 'Indicates the total number of folders held within the orphaned Teams.'
            },
            teamsList: {
                title: 'All orphaned teams',
                description: 'This list shows you all the orphaned Teams without Owners. It\'s important that your Team has a purpose in life and has at least an Owner who is responsible for managing / governing the Team. If you\'re not using, let\'s archive it.'
            },
            visibilityChart: {
                title: 'Orphaned teams by visibility',
                description: 'This shows you your orphaned Teams by their visibility.',
            },
        }
    },
    channels: {
        title: 'Channels Overview',
        blurb: '',
        channelsCounter: {
            title: 'Number of channels',
            description: ' Indicates the number of the Channels across all your Teams. Channels provide a great way to structure and organise your Teams. Channels can exist as Private or Standard Channels. A large number of Channels or lack of governance can lead to siloed pockets of information.'
        },
        visibilityChart: {
            title: 'Channels by visibility',
            description: 'The chart displays the percentage split between Private and Standard Channels. Private Channels were introduced by Microsoft to enable users to manage permissions. A large proportion of Private Channels can be a reason for concern. Should the Channel by created as Private? Should the Channel be a Microsoft Teams? Is the membership of the Channel the same as the parent Team?'
        },
        growthChart: {
            title: 'Channels growth',
            description: 'The chart shows your Channel growth over the last {months} months. Based upon your data we\'ve calculated your projected growth for the next {months} months.'
        },
    },
    apps: {
        title: 'Installed Team apps information',
        blurb: '',
        appsCounter: {
            title: 'Third-party apps',
            description: 'Indicates the number of third-party (i.e. non-Microsoft) apps that are being used across your Microsoft Teams. Apps are a great way to streamlines processes within Teams and collaborate with your favourite third-party applications. However, without the necessary diligence your organisational data might be stored externally and may be in breach of your organisational guidelines.',
        },
        appsList: {
            title: 'Popular third-party apps',
            description: 'You\'ll find the most popular third-party apps installed across your Microsoft Teams.',
        },
        teamsList: {
            title: 'Teams with third-party apps',
            description: 'You\'ll find a list of your Teams which have third-party apps installed.',
        },
    }
}