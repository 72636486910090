import AuthProvider from "../auth/AuthProvider";

export class TenantService {
    private static tenantName: string = "";
    private static scope: string = "https://graph.microsoft.com/User.Read";
    public static async getTenantName(authProvider: AuthProvider) : Promise<string> {
        if(this.tenantName != "") {
            return this.tenantName;
        }

        const accessTokenResult = await authProvider.acquireToken({
            scopes: [
                this.scope
            ]
        });

        const response = await fetch("https://graph.microsoft.com/v1.0/organization?$select=verifiedDomains", {
            method: 'GET',
            headers: new Headers({
                'Authorization': `Bearer ${accessTokenResult.accessToken}`
            })
        });

        if(response.ok) {
            const result = await response.json();
            if(result.value.length == 0) {
                return "";
            }

            const domains = result.value[0].verifiedDomains.filter((x: any) => x.name.indexOf(".onmicrosoft.com") > -1);
            if(domains.length > 0) {
                this.tenantName = domains[0].name.split('.')[0];
                return this.tenantName;
            }

            return "";
        }

        return "";
    }
}