import * as React from 'react';
import IStatsItem from '../../../models/Report/IStatsItem';

export interface IStatProps {
    stats: IStatsItem[];
}

export default class TenancyStats extends React.Component<IStatProps> {
    public render() {
        return (
            <div className="tab-space text-center">
                <p className="text-gray"><strong>This report is based on the following information in your tenancy:</strong></p>
                <ul className="list-unstyled list-inline text-gray">
                    {this.renderItems()}
                </ul>
            </div>
        );
    }

    private renderItems(): React.ReactElement[] | React.ReactElement {
        const { stats } = this.props;
        if (stats.length > 0) {
            return stats.map((stat,i) => <li key={`stat_${i}`} className="list-inline-item"><b>{stat.value}</b> {stat.name}</li>);
        } else {
            return <span className="description">(No stats)</span>;
        }
    }
}
