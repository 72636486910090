import * as React from 'react';
import PieChartCard from '../../../components/Report/widgets/PieChartCard';
import TableCard from '../../../components/Report/widgets/TableCard';
import CounterCard from '../../../components/Report/widgets/CounterCard';
import ITableItem from '../../../models/Report/ITableItem';
import { IOrphanedTeam } from '../../../models/Report/ITeam';
import { IOverviewReport, ITeamsReport } from '../../../models/Report/ITabModels';
import { PieChartData } from '../../../models/Report/IChart';
import Utilities from '../../../services/Utils';
import { ReportStrings } from '../../../settings/Report/ReportSettings';
import ClampLines from 'react-clamp-lines';
import uniqid from 'uniqid';

export interface IOverviewProps {
    reportData?: IOverviewReport;
    orphanedTeams: IOrphanedTeam[];
    allTeams?: ITeamsReport;
    onTeamClick(teamId: string): void;
    loading?: boolean;
    onPageChange(pageNumber: number): void;
}

export interface IOverviewState {
    visibilityChartData: PieChartData;
}

export default class OverviewTab extends React.PureComponent<IOverviewProps, IOverviewState> {

    constructor(props: IOverviewProps) {
        super(props);

        this.state = {
            visibilityChartData: {
                dataLabel: { visible: true, position: 'Outside', name: 'x' },
                data: []
            }
        }
    }

    public componentDidMount(): void {
        this.formatVisibilityChartData();
    }

    public componentDidUpdate(prevProps: IOverviewProps) {
        if (prevProps.reportData !== this.props.reportData) {
            this.formatVisibilityChartData();
        }
    }

    public render() {
        const { visibilityChartData: visibilityChartData } = this.state;
        const { loading, reportData } = this.props;

        return <div>
            <div className="section text-center">
                <div className="row">
                    <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12">
                        <CounterCard title={ReportStrings.overview.teamsCounter.title} value={reportData?.totalTeams} icon="groups" colour={this.getCounterColour(reportData?.totalTeams)} loading={loading}>
                            <div className="stats">
                                <i className={`material-icons text-${this.getCounterColour(reportData?.totalTeams)}`}>{this.getCounterIcon(reportData?.totalTeams)}</i>
                                <ClampLines
                                    id={`countercard_${uniqid()}`}
                                    text={ReportStrings.overview.teamsCounter.description}
                                    lines={3}
                                    ellipsis="..."
                                    buttons={true}
                                    className="clamped-text"
                                    moreText={'Read more'}
                                    lessText={'Read less'} />
                            </div>
                        </CounterCard>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12">
                        <CounterCard title={ReportStrings.overview.duplicateCounter.title} value={reportData?.totalDuplicateTeams} icon="content_copy" colour={this.getCounterColour(reportData?.totalDuplicateTeams)} loading={loading}>
                            <div className="stats">
                                <i className={`material-icons text-${this.getCounterColour(reportData?.totalDuplicateTeams)}`}>{this.getCounterIcon(reportData?.totalDuplicateTeams)}</i>
                                <ClampLines
                                    id={`countercard_${uniqid()}`}
                                    text={ReportStrings.overview.duplicateCounter.description}
                                    lines={3}
                                    ellipsis="..."
                                    buttons={true}
                                    className="clamped-text"
                                    moreText={'Read more'}
                                    lessText={'Read less'} />
                            </div>
                        </CounterCard>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12">
                        <CounterCard title={ReportStrings.overview.appsCounter.title} value={reportData?.totalExternalApps} icon="inventory_2" colour={this.getCounterColour(reportData?.totalExternalApps)} loading={loading}>
                            <div className="stats">
                                <i className={`material-icons text-${this.getCounterColour(reportData?.totalExternalApps)}`}>{this.getCounterIcon(reportData?.totalExternalApps)}</i>
                                <ClampLines
                                    id={`countercard_${uniqid()}`}
                                    text={ReportStrings.overview.appsCounter.description}
                                    lines={3}
                                    ellipsis="..."
                                    buttons={true}
                                    className="clamped-text"
                                    moreText={'Read more'}
                                    lessText={'Read less'} />
                            </div>
                        </CounterCard>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12">
                        <CounterCard title={ReportStrings.overview.guestsCounter.title} value={reportData?.totalGuestTeams} icon="people_outline" colour={this.getCounterColour(reportData?.totalGuestTeams)} loading={loading}>
                            <div className="stats">
                                <i className={`material-icons text-${this.getCounterColour(reportData?.totalGuestTeams)}`}>{this.getCounterIcon(reportData?.totalGuestTeams)}</i>
                                <ClampLines
                                    id={`countercard_${uniqid()}`}
                                    text={ReportStrings.overview.guestsCounter.description}
                                    lines={3}
                                    ellipsis="..."
                                    buttons={true}
                                    className="clamped-text"
                                    moreText={'Read more'}
                                    lessText={'Read less'} />
                            </div>
                        </CounterCard>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12">
                        <CounterCard title={ReportStrings.overview.totalGuestsCounter.title} value={reportData?.totalGuests} icon="settings_accessibility" colour={this.getCounterColour(reportData?.totalGuests)} loading={loading}>
                            <div className="stats">
                                <i className={`material-icons text-${this.getCounterColour(reportData?.totalGuests)}`}>{this.getCounterIcon(reportData?.totalGuests)}</i>
                                <ClampLines
                                    id={`countercard_${uniqid()}`}
                                    text={ReportStrings.overview.totalGuestsCounter.description}
                                    lines={3}
                                    ellipsis="..."
                                    buttons={true}
                                    className="clamped-text"
                                    moreText={'Read more'}
                                    lessText={'Read less'} />
                            </div>
                        </CounterCard>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12">
                        <CounterCard title={ReportStrings.overview.storageCounter.title} value={Utilities.convertToStorageSizeString(reportData?.totalStorageUsed)} icon="storage" colour={this.getCounterColour(reportData?.totalStorageUsed)} loading={loading}>
                            <div className="stats">
                                <i className={`material-icons text-${this.getCounterColour(reportData?.totalStorageUsed)}`}>{this.getCounterIcon(reportData?.totalStorageUsed)}</i>
                                <ClampLines
                                    id={`countercard_${uniqid()}`}
                                    text={ReportStrings.overview.storageCounter.description}
                                    lines={3}
                                    ellipsis="..."
                                    buttons={true}
                                    className="clamped-text"
                                    moreText={'Read more'}
                                    lessText={'Read less'} />
                            </div>
                        </CounterCard>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-6 col-xl-7">
                    {this.renderTeamsList()}
                </div>
                <div className="col-lg-6 col-xl-5">
                    <PieChartCard title={ReportStrings.overview.visibilityChart.title} description={ReportStrings.overview.visibilityChart.description} chartOptions={visibilityChartData} colour="info" />
                </div>
            </div>
        </div>;
    }

    private renderTeamsList(): React.ReactElement {
        const { orphanedTeams, allTeams } = this.props;
        if (orphanedTeams.length > 0) {
            //Rendered orphaned teams, if any
            return <TableCard
                id={`all_orphaned_teams_overview`}
                title={ReportStrings.overview.orphanedTeamsList.title}
                description={ReportStrings.overview.orphanedTeamsList.description}
                colour={`danger`}
                items={orphanedTeams}
                onTitleClick={this.props.onTeamClick}
                enablePaging={false}
                columnInfo={[
                    { headerText: "Name", field: "displayName", width: "100", clipMode: "EllipsisWithTooltip" },
                    { headerText: "Created", field: "createdDateTime", width: "100", type: "datetime", format: "dd/MM/yyyy" },
                ]}
            />
        } else {
            //Render all teams
            return <TableCard
                id={`all_teams_overview`}
                title={ReportStrings.overview.allTeamsList.title}
                description={ReportStrings.overview.allTeamsList.description}
                colour="primary"
                items={allTeams?.results}
                currentPage={allTeams?.currentPage}
                totalPages={allTeams?.pageCount}
                resizeable={true}
                enablePaging={true}
                columnInfo={[
                    { headerText: "Name", field: "displayName", clipMode: "EllipsisWithTooltip" },
                    { headerText: "Description", field: "description", clipMode: "EllipsisWithTooltip" },
                    { headerText: "Visibility", field: "visibility" },
                    { headerText: "Owners", field: "ownersCount" },
                    { headerText: "Members", field: "membersCount" },
                    { headerText: "Guests", field: "guestsCount" },
                    { headerText: "Created", field: "createdDateTime", type: "datetime", format: "dd/MM/yyyy" },
                    { headerText: "Channels", field: "channelsCount" },
                    { headerText: "Has private channels", field: "hasPrivateChannels" },
                    { headerText: "Total Documents", field: "documentsCount" },
                    { headerText: "Total Folders", field: "foldersCount" },
                    { headerText: "Space Used", field: "spaceUsed" },
                    { headerText: "Last Activity Date", field: "lastActivityDate", type: "datetime", format: "dd/MM/yyyy" },
                ]}
                onPageChange={this.props.onPageChange} />
        }
    }

    private getCounterIcon(value?: number): string {
        if (value && (value > 0)) {
            return "privacy_tip";
        }
        return "verified_user";
    }

    private getCounterColour(value?: number): "primary" | "success" | "warning" | "danger" | "info" {
        return "warning";
    }

    private formatVisibilityChartData(): void {
        const { reportData } = this.props;
        let formatted: any[] = [];
        if (reportData) {
            if (reportData.totalPrivateTeams)
                formatted.push({ x: 'Private Teams', y: reportData.totalPrivateTeams, r: '90%' });
            if (reportData.totalPublicTeams)
                formatted.push({ x: 'Public Teams', y: reportData.totalPublicTeams, r: '90%' });
        }
        this.setState({
            ...this.state,
            visibilityChartData: {
                ...this.state.visibilityChartData,
                data: formatted
            }
        });
    }
}


