import * as React from 'react';

export interface IWrapperProps {
}

export default class Wrapper extends React.Component<IWrapperProps> {
  constructor(props: IWrapperProps) {
    super(props);

  }

  public render() {
    return (
      <div className={`main main-raised report-wrapper`}>
          {this.props.children}
      </div>
    );
  }
}
