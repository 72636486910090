import * as React from 'react';
import LineChartCard from '../../../components/Report/widgets/LineChartCard';
import PieChartCard from '../../../components/Report/widgets/PieChartCard';
import CounterCard from '../../../components/Report/widgets/CounterCard';
import { IChannelsReport } from '../../../models/Report/ITabModels';
import { LineChartOptions, PieChartData } from '../../../models/Report/IChart';
import Utilities from '../../../services/Utils';
import { ReportStrings } from '../../../settings/Report/ReportSettings';
import ClampLines from 'react-clamp-lines';
import uniqid from 'uniqid';

export interface IChannelsTabProps {
  onTeamClick(teamId: string): void;
  channelsInfo?: IChannelsReport;
  loading: boolean;
  creationPeriod: number;
}

export interface IChannelsTabState {
  visibilityChartData: PieChartData;
  creationChartData: ICreationChart;
}

export interface ICreationChart extends LineChartOptions {
  data: {
    period: string;
    count: number;
  }[];
}

export default class ChannelsTab extends React.Component<IChannelsTabProps, IChannelsTabState> {
  constructor(props: IChannelsTabProps) {
    super(props);

    this.state = {
      creationChartData: {
        primaryXAxis: { valueType: 'DateTime', labelFormat: 'MMM y', title: 'Channels created by month' },
        legendSettings: { visible: true },
        tooltip: { enable: true, shared: false },
        data: []
      },
      visibilityChartData: {
        dataLabel: { visible: true, position: 'Outside', name: 'x' },
        data: []
      },
    }
  }

  public componentDidMount(): void {
    this.formatVisibilityChartData();
    this.formatCreationChartData();
  }

  public componentDidUpdate(prevProps: IChannelsTabProps) {
    if (prevProps.channelsInfo !== this.props.channelsInfo) {
      this.formatVisibilityChartData();
      this.formatCreationChartData();
    }

    if (prevProps.creationPeriod !== this.props.creationPeriod) {
      this.formatCreationChartData();
    }
  }

  public render() {
    const { visibilityChartData, creationChartData } = this.state;
    const { creationPeriod, channelsInfo } = this.props;
    return (
      <div>
        <h4 className="title">{ReportStrings.channels.title}</h4>
        <div className="row">
          <div className="col-lg-8 col-md-12 col-sm-12">
            <LineChartCard
              title={ReportStrings.channels.growthChart.title}
              description={ReportStrings.channels.growthChart.description.replaceAll('{months}', creationPeriod.toString())}
              chartOptions={creationChartData}
              colour="primary"
              seriesInfo={[{
                dataSource: creationChartData.data,
                xName: 'period',
                yName: 'count',
                name: 'Channels'
              }]}
            />
          </div>
          <div className="col-lg-4 col-md-12 col-sm-12">
            <div className="row text-center">
              <div className="col-sm-12">
                <CounterCard title={ReportStrings.channels.channelsCounter.title} value={channelsInfo?.totalChannelsCount} icon="view_stream" colour="danger">
                  <div className="stats">
                    <i className="material-icons text-danger">privacy_tip</i>
                    <ClampLines
                      id={`countercard_${uniqid()}`}
                      text={ReportStrings.channels.channelsCounter.description}
                      lines={3}
                      ellipsis="..."
                      buttons={true}
                      className="clamped-text"
                      moreText={'Read more'}
                      lessText={'Read less'} />
                  </div>
                </CounterCard>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <PieChartCard title={ReportStrings.channels.visibilityChart.title} description={ReportStrings.channels.visibilityChart.description} chartOptions={visibilityChartData} />
              </div>
            </div>

          </div>
        </div>
      </div>
    );
  }

  // private renderCreationChangeDescription(): React.ReactElement {
  //   const data = this.state.creationChartData.data;
  //   let content = <span><i className="fa fa-info-circle"></i> (Not enough data for analysis).</span>
  //   if (data.length > 1) {
  //     const lastMonthCount = data[data.length - 2].count;
  //     const thisMonthCount = data[data.length - 1].count;
  //     if (thisMonthCount === lastMonthCount) {
  //       content = <span><span className="text-gray"><i className="fa fa-minus"></i> No change</span> in creations compared to last month.</span>;
  //     } else if (thisMonthCount > lastMonthCount) {
  //       const change: number = ((thisMonthCount - lastMonthCount) / lastMonthCount * 100);
  //       content = <span><span className="text-success"><i className="fa fa-long-arrow-up"></i> {isFinite(change) && `${change}%`} </span> increase in creations compared to last month.</span>;
  //     } else {
  //       const change: number = ((lastMonthCount - thisMonthCount) / lastMonthCount * 100);
  //       content = <span><span className="text-danger"><i className="fa fa-long-arrow-down"></i> {isFinite(change) && `${change}%`} </span> decrease in creations compared to last month.</span>;
  //     }
  //   }

  //   return <p className="card-category">{content}</p>;
  // }

  private formatCreationChartData(): void {
    //Loop through each of the previous months and get count
    const { channelsInfo, creationPeriod } = this.props;
    let formatted: any[] = [];

    const monthNames = Utilities.getMonths();
    let today = new Date();
    let d: Date;

    for (let i = creationPeriod; i >= 0; i--) {
      d = new Date(today.getFullYear(), today.getMonth() - i, 1);
      let month = monthNames[d.getMonth()];
      let year = d.getFullYear();

      if (channelsInfo && channelsInfo.monthlyCreationReport && (channelsInfo.monthlyCreationReport.length > 0)) {
        let countIndex = channelsInfo.monthlyCreationReport.findIndex(i => ((i.month === month) && (i.year === year)));
        if (countIndex !== -1) {
          let storedValues = channelsInfo.monthlyCreationReport[countIndex];
          formatted.push({
            period: `${d.toISOString()}`,
            count: storedValues.totalChannelsCreated
          });
        } else {
          formatted.push({
            period: `${d.toISOString()}`,
            count: 0
          });
        }
      }
    }

    this.setState({
      creationChartData: {
        ...this.state.creationChartData,
        data: formatted
      }
    });
  }

  private formatVisibilityChartData(): void {
    const { channelsInfo } = this.props;
    let formatted: any[] = [];
    if (channelsInfo && channelsInfo.visibilityReport) {
      formatted.push({ x: 'Private Channels', y: channelsInfo.visibilityReport.privateChannelsCount, r: '90%' });
      formatted.push({ x: 'Shared Channels', y: channelsInfo.visibilityReport.sharedChannelsCount, r: '90%' });
      formatted.push({ x: 'Standard Channels', y: channelsInfo.visibilityReport.standardChannelsCount, r: '90%' });
    }
    this.setState({
      visibilityChartData: {
        ...this.state.visibilityChartData,
        data: formatted
      }
    });
  }
}
