import AuthProvider from "../auth/AuthProvider";

export class SharePointService {
    
    public static async checkAppCatalogExists(authProvider: AuthProvider, tenantName: string) : Promise<boolean> {
        const account = authProvider.getAccount();
        if(account == null) {
            return false;
        }

        const rootSPUrl: string = `https://${tenantName}.sharepoint.com`;
        const tokenScope = `${rootSPUrl}/.default`;
        var accessTokenResult = await authProvider.acquireToken({
            scopes: [
                tokenScope
        ]});

        const response = await fetch(`${rootSPUrl}/_api/SP_TenantSettings_Current`, {
            method: 'GET',
            headers: new Headers({
                'Authorization': `Bearer ${accessTokenResult.accessToken}`,
                "accept": "application/json;odata=verbose"
            })
        });

        if(response.ok) {
            const result = await response.json();
            const corporateCatalogUrl = result.d.CorporateCatalogUrl;
            return corporateCatalogUrl != undefined && corporateCatalogUrl != null && corporateCatalogUrl != "";
        }

        return false;
    }
}