import * as React from 'react';
import { RouteComponentProps } from "react-router";
import AuthProvider from '../../auth/AuthProvider';
import Loading from '../../components/Loading';
import Alert from '../../components/Alert';
import { ReportSettings, ReportStrings } from '../../settings/Report/ReportSettings';
import Header from '../../components/Report/fields/Header';
import Wrapper from '../../components/Report/fields/Wrapper';
import NavPills from '../../components/Report/controls/NavPills';
import IPillOption from '../../models/Report/IPillOptions';
import TenancyStats from '../../components/Report/widgets/TenancyStats';
import IStatsItem from '../../models/Report/IStatsItem';
import OverviewTab from './Tabs/OverviewTab';
import TeamsTab from './Tabs/TeamsTab';
import ChannelsTab from './Tabs/ChannelsTab';
import AppsTab from './Tabs/AppsTab';
import * as QueryString from "query-string";
import { IReportService } from '../../services/IReportService';
import { IChannelsReport, IExternalAppsSummary, IOrphanedOverview, IOverviewReport, ITeamsCreatedSummary, ITeamsReport } from '../../models/Report/ITabModels';
import { IOrphanedTeam } from '../../models/Report/ITeam';
import { ItemStorage } from '../../itemstorage';
import ReRunButton from '../../components/Report/widgets/ReRunButton';

export interface IReportProps extends RouteComponentProps<{}> {
  authProvider: AuthProvider;
  reportService: IReportService;
}

export interface IReportState {
  loading: boolean;
  errors?: string[];
  tabs: IPillOption[];
  data: {
    stats: IStatsItem[];
    orphanedTeams: IOrphanedTeam[];
    overviewInfo?: IOverviewReport;
    allTeams?: ITeamsReport;
    orphanedOverviewInfo?: IOrphanedOverview;
    creationData?: ITeamsCreatedSummary[];
    growthPrediction?: ITeamsCreatedSummary[];
    channelsInfo?: IChannelsReport;
    externalApps?: IExternalAppsSummary;
    teamsWithExternalApps?: ITeamsReport;
    healthScore: number;
  };
  paging: {
    teamsWithExtApps: number;
    allTeams: number;
  };
  settings: {
    teamCreationChartPeriod: number;
    channelCreationChartPeriod: number;
    topExtAppsLimit: number;
  };
  activeTab: number;
  reportId: string;
  // showTeamInfo: boolean;
  // selectedTeamId: string;
}

export default class TenancyReport extends React.Component<IReportProps, IReportState> {


  constructor(props: IReportProps) {
    super(props);

    this.state = {
      loading: true,
      // showTeamInfo: false,
      reportId: "",
      tabs: [
        {
          icon: `preview`,
          title: `Overview`
        },
        {
          icon: `groups`,
          title: `Teams`
        },
        {
          icon: `fact_check`,
          title: `Channels`
        },
        {
          icon: `dashboard_customize`,
          title: `Apps`
        }
      ],
      data: {
        stats: [{
          name: `Teams`,
          value: 0
        }, {
          name: `Team Channels`,
          value: 0
        }, {
          name: `Total Documents`,
          value: 0
        }, {
          name: `Total Folders`,
          value: 0
        }],
        orphanedTeams: [],
        healthScore: 100
      },
      paging: {
        teamsWithExtApps: 1,
        allTeams: 1
      },
      settings: {
        teamCreationChartPeriod: 12,
        channelCreationChartPeriod: 12,
        topExtAppsLimit: 10
      },
      activeTab: 0,
    };
  }

  public async componentDidMount() {
    const params = QueryString.parse(this.props.location.search);
    let reportId = undefined;
    let fromStorage = false;
    if (params.reportid) {
      ItemStorage.setStorageItemAsString(ReportSettings.constants.reportId, params.reportid);
      reportId = ItemStorage.getStorageItemAsString(ReportSettings.constants.reportId);
    } else {
      reportId = ItemStorage.getStorageItemAsString(ReportSettings.constants.reportId);
      ItemStorage.clearStorageItem(ReportSettings.constants.reportId);
      fromStorage = true;
    }

    if (reportId) {
      this.props.authProvider.ensureLogin(this.props.authProvider.getApiScope());
      if (fromStorage) {
        window.location.href = `${window.location.href.replace("#", "")}?reportid=${reportId}`;
      }

      await this.loadInitialData(reportId);
      this.setState({ loading: false }); //TODO Remove this
      window.scrollTo({ top: 0 });
    } else {
      console.log('Report ID not specified, redirecting to products page...');
      window.location.href = ReportSettings.constants.productPageUrl;
    }
  }


  public render() {
    if (this.state.loading) {
      return <Loading />;
    } else {
      return this.renderBody();
    }
  }

  private renderBody(): React.ReactElement {
    return <>
      {this.renderHeader()}
      <Wrapper>
        <div className="container-fluid">
          {this.renderReport()}
          <hr />
          {this.renderTenancyStats()}
        </div>
      </Wrapper>
      {this.renderErrors()}
      <ReRunButton />
      {/* <PrintButton printClick={this.onPrintClick}/> */}
    </>
  }

  private renderHeader(): React.ReactElement {
    return <Header
      title={ReportStrings.headerTitle}
      description={ReportStrings.headerDescription}
      healthScore={this.state.data.healthScore}
    />
  }

  private renderReport(): React.ReactElement {

    return (<>
      <div className="section">
        <div className="row">
          <div className="col-md-6 ml-auto mr-auto">
            <NavPills options={this.state.tabs} pillClicked={this.pillClicked} currentTab={this.state.activeTab} />
          </div>
        </div>
      </div>
      <div className="tab-content tab-space">
        <div className="tab-pane active show">
          <div className="row">
            <div className="col-md-8 ml-auto mr-auto">
              {this.renderAbout()}
              {this.renderTab()}
            </div>
          </div>
        </div>
      </div>
    </>);
  }

  private renderTenancyStats(): React.ReactElement {
    return (<div className="row">
      <div className="col-md-8 ml-auto mr-auto">
        <TenancyStats stats={this.state.data.stats} />
      </div>
    </div>);
  }

  private renderTab(): React.ReactElement {
    switch (this.state.activeTab) {
      case 0: return <OverviewTab onTeamClick={this.onTeamClick} reportData={this.state.data.overviewInfo} loading={this.state.loading} orphanedTeams={this.state.data.orphanedTeams} allTeams={this.state.data.allTeams} onPageChange={this.teamsTabAllTablePageChange} />;
      case 1: return <TeamsTab onTeamClick={this.onTeamClick} loading={this.state.loading} allTeams={this.state.data.allTeams} reportData={this.state.data.overviewInfo} orphanedTeams={this.state.data.orphanedTeams} orphanedOverviewInfo={this.state.data.orphanedOverviewInfo} creationData={this.state.data.creationData} predictionData={this.state.data.growthPrediction} creationPeriod={this.state.settings.teamCreationChartPeriod} teamsTablePageChange={this.teamsTabAllTablePageChange}/>;
      case 2: return <ChannelsTab onTeamClick={this.onTeamClick} loading={this.state.loading} channelsInfo={this.state.data.channelsInfo} creationPeriod={this.state.settings.channelCreationChartPeriod} />;
      case 3: return <AppsTab onTeamClick={this.onTeamClick} loading={this.state.loading} externalApps={this.state.data.externalApps} teamsWithExternalApps={this.state.data.teamsWithExternalApps} topExtAppsLimit={this.state.settings.topExtAppsLimit} teamsTablePageChange={this.appsTabTeamTablePageChange} />;
      default: return <div className="row"><div className="col-xs-12"><p>(No tab content)</p></div></div>;
    }
  }

  private renderAbout(): React.ReactElement {
    let blurb: string = "";
    switch (this.state.activeTab) {
      case 0: blurb = ReportStrings.overview.blurb; break;
      case 1: blurb = ReportStrings.teams.blurb; break;
      case 2: blurb = ReportStrings.channels.blurb; break;
      case 3: blurb = ReportStrings.apps.blurb; break;
      default: blurb = "(No tab selected)";
    }

    return <h5 className="text-info text-center">{blurb}</h5>;
  }

  private pillClicked = (pillIndex: number): void => {
    //Reset paging
    if (this.state.activeTab !== pillIndex) {
      this.setState({
        ...this.state,
        activeTab: pillIndex,
        paging: {
          ...this.state.paging,
          allTeams: 1,
          teamsWithExtApps: 1
        }
      });

      this.fetchTabData(pillIndex);
    }
  }

  private fetchTabData(pillIndex: number): void {
    //TODO
    switch (pillIndex) {
      case 0: this.getOverviewTabData(); break;
      case 1: this.getTeamsTabData(); break;
      case 2: this.getChannelsTabData(); break;
      case 3: this.getAppsTabData(); break;
      default: break;
    }
  }

  private renderErrors(): React.ReactNode | void {
    if (this.state.errors && (this.state.errors.length > 0)) {
      return this.state.errors.map((error, i) => {
        return <Alert message={error} key={`errormessage_${i}`} />;
      });
    }
  }

  private async loadInitialData(reportId: string): Promise<void> {
    this.setState({
      reportId: reportId
    }, async () => {
      await this.getOverviewTabData();
    });
  }

  private async getTeamsTabData(): Promise<void> {
    const { reportId, settings } = this.state;
    try {
      let accessToken = await this.props.authProvider.getApiAccessToken();
      let orphanedOverviewData = await this.props.reportService.getOrphanedOverviewData(reportId, accessToken);
      let teamsGrowth = await this.props.reportService.getTeamsCreated(reportId, settings.teamCreationChartPeriod, accessToken);
      let growthPrediction = await this.props.reportService.getGrowthPrediction(reportId, 12, accessToken);

      await this.getTeams();
      this.setState({
        loading: false,
        data: {
          ...this.state.data,
          orphanedOverviewInfo: orphanedOverviewData,
          creationData: teamsGrowth,
          growthPrediction: growthPrediction
        }
      });

    } catch (err) {
      console.log(`Could not get teams tab data: ${err}`);
    }
  }

  private async getTeams(): Promise<void> {
    const { reportId, paging } = this.state;
    try {
      let accessToken = await this.props.authProvider.getApiAccessToken();
      let allTeams = await this.props.reportService.getAllTeams(reportId, accessToken, paging.allTeams, ReportSettings.constants.listItemLimit);
      this.setState({
        loading: false,
        data: {
          ...this.state.data,
          allTeams: allTeams
        }
      });
    } catch (err) {
      console.log(`Could not get get Teams data: ${err}`);
    }

  }

  private async getChannelsTabData(): Promise<void> {
    const { reportId, settings } = this.state;
    try {
      let accessToken = await this.props.authProvider.getApiAccessToken();
      let data = await this.props.reportService.getChannelsSummary(reportId, settings.channelCreationChartPeriod, accessToken);
      this.setState({
        loading: false,
        data: {
          ...this.state.data,
          channelsInfo: data
        }
      });
    } catch (err) {
      console.log(`Could not get channels tab data: ${err}`);
    }
  }

  private async getAppsTabData(): Promise<void> {
    const { reportId, settings } = this.state;
    try {
      let accessToken = await this.props.authProvider.getApiAccessToken();
      let summary = await this.props.reportService.getExternalAppsSummary(reportId, settings.topExtAppsLimit, accessToken); //TODO: Top
      await this.getExtAppsTeams();
      this.setState({
        loading: false,
        data: {
          ...this.state.data,
          externalApps: summary,
        }
      });
    } catch (err) {
      console.log(`Could not get apps tab data: ${err}`);
    }
  }

  private async getExtAppsTeams(): Promise<void> {
    const { reportId, paging } = this.state;
    try {
      let accessToken = await this.props.authProvider.getApiAccessToken();
      let teams = await this.props.reportService.getTeamsWithExternalApps(reportId, accessToken, paging.teamsWithExtApps, ReportSettings.constants.listItemLimit);
      this.setState({
        loading: false,
        data: {
          ...this.state.data,
          teamsWithExternalApps: teams,
        }
      });
    } catch (err) {
      console.log(`Could not get teams with third-party apps data: ${err}`);
    }
  }

  private async getOverviewTabData(): Promise<void> {
    const { reportId } = this.state;
    try {
      let accessToken = await this.props.authProvider.getApiAccessToken();
      let overviewInfo = await this.props.reportService.getOverviewData(reportId, accessToken);
      let orphanedTeams = await this.props.reportService.getOrphanedTeams(reportId, accessToken);
      await this.getExtAppsTeams();

      if (orphanedTeams.length === 0) {
        //Get all teams if there are no orphaned teams
        this.getTeamsTabData();
      }

      this.setState({
        ...this.state,
        loading: false,
        data: {
          ...this.state.data,
          overviewInfo: overviewInfo,
          stats: [{
            name: `Teams`,
            value: overviewInfo.totalTeams
          }, {
            name: `Team Channels`,
            value: overviewInfo.totalChannels
          }, {
            name: `Total Documents`,
            value: overviewInfo.totalDocuments
          }, {
            name: `Total Folders`,
            value: overviewInfo.totalFolders
          }],
          orphanedTeams: orphanedTeams
        }
      }, () => {
        this.calculateHealthScore();
      });
    } catch (err) {
      console.log(`Could not get overview tab data: ${err}`);
    }
  }

  private calculateHealthScore(): void {
    let score: number = 100;
    let totalTeams: number = this.state.data.overviewInfo ? this.state.data.overviewInfo.totalTeams : 0;

    if (totalTeams) {
      //Orphaned teams score
      let totalOrphanedTeams: number = this.state.data.orphanedTeams.length;
      score -= ((totalOrphanedTeams / totalTeams) * 100) * ReportSettings.scoreWeightings.orphanedTeams;

      //Duplicate teams score
      let totalDuplicateTeams: number = this.state.data.overviewInfo ? this.state.data.overviewInfo.totalDuplicateTeams : 0;
      score -= ((totalDuplicateTeams / totalTeams) * 100) * ReportSettings.scoreWeightings.duplicateTeams;

      //Teams with guests score
      let teamsWithGuests: number = this.state.data.overviewInfo ? this.state.data.overviewInfo.totalGuestTeams : 0;
      score -= ((teamsWithGuests / totalTeams) * 100) * ReportSettings.scoreWeightings.teamsWithGuests;

      //Teams with external apps
      let totalExternalApps: number = this.state.data.teamsWithExternalApps ? this.state.data.teamsWithExternalApps.rowCount : 0;
      score -= ((totalExternalApps / totalTeams) * 100) * ReportSettings.scoreWeightings.externalApps;

    }

    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        healthScore: Math.ceil(score)
      }
    });
  }

  private teamsTabAllTablePageChange = (pageNumber: number): void => {
    if (this.state.data.allTeams) {
      if ((pageNumber > 0) && (pageNumber <= this.state.data.allTeams?.pageCount))
        this.setState({
          ...this.state,
          paging: {
            ...this.state.paging,
            allTeams: pageNumber
          }
        }, () => {
          this.getTeams();
        });
    }
  }

  private appsTabTeamTablePageChange = (pageNumber: number): void => {
    if (this.state.data.teamsWithExternalApps) {
      let pageCount = this.state.data.teamsWithExternalApps ? this.state.data.teamsWithExternalApps.pageCount : 1;
      console.log(pageCount);
      if ((pageNumber > 0) && (pageNumber <= pageCount)){
        this.setState({
          ...this.state,
          paging: {
            ...this.state.paging,
            teamsWithExtApps: pageNumber
          }
        }, () => {
          this.getAppsTabData();
        });
      }        
    }
  }

  private onTeamClick = (teamId: string): void => {
    //TODO Go to team
  }

  // private onBackClick = (): void => {
  //   this.setState({
  //     // showTeamInfo: false
  //   });
  // }

  private onPrintClick = (): void => {
    alert('Print feature coming soon!');
  }

}
