import { ColumnDirective, ColumnsDirective, GridComponent, Inject, ColumnModel, Resize, Grid } from '@syncfusion/ej2-react-grids';
import Pagination from '../controls/Pagination';
import * as React from 'react';
import ClampLines from 'react-clamp-lines';
import uniqid from 'uniqid';
import Utilities from '../../../services/Utils';
import { ITeam } from '../../../models/Report/ITeam';
export interface ITableProps {
  id: string;
  colour?: "success" | "warning" | "danger" | "primary" | "info" | "rose";
  title: string;
  description?: string;
  items?: any[];
  onTitleClick?(teamId: string): void;
  columnInfo: ColumnModel[];
  resizeable?: boolean;
  enablePaging: boolean;
  totalPages?: number;
  currentPage?: number;
  onPageChange?(pageNumber: number): void;
}

export interface ITableState {
}

export default class TableCard extends React.Component<ITableProps, ITableState> {
  public grid: Grid | null = null;

  constructor(props: ITableProps) {
    super(props);

    this.state = {
    };

    this.onTitleClick = this.onTitleClick.bind(this);
    this.dataBound = this.dataBound.bind(this);
  }

  public render() {
    const { title, description: subtitle, colour } = this.props;

    return (
      <div className="card widget-card">
        <div className={`card-header card-header-${colour ? colour : 'info'}`}>
          <h4 className="card-title">{title}</h4>
          {subtitle && <div className="category">
            <ClampLines
              id={`countercard_${uniqid()}`}
              text={subtitle}
              lines={3}
              ellipsis="..."
              buttons={true}
              className="clamped-text"
              moreText={'Read more'}
              lessText={'Read less'} />
          </div>}
        </div>
        <div className="card-body">
          {this.renderBody()}
        </div>
      </div>
    );
  }

  private renderBody(): React.ReactElement {
    const { items, children } = this.props;

    if (items) {
      if (items.length > 0) {
        return <>
          {this.renderTable()}
          {children}
          {this.renderPagination()}
        </>
      } else {
        return this.renderNoItems();
      }
    } else {
      return this.renderPlaceholder();
    }
  }

  private renderTable(): React.ReactElement {
    const { items, resizeable } = this.props;
    return <div className="row">
      <div className="col-sm-12">
        <GridComponent dataSource={items} allowResizing={resizeable} dataBound={this.dataBound} ref={g => this.grid = g} id={this.props.id}>
          {resizeable && <Inject services={[Resize]} />}
          {this.renderTableColumns()}
        </GridComponent>
      </div>
    </div>;
  }

  private renderTableColumns(): React.ReactElement {
    const { columnInfo } = this.props;
    return (<ColumnsDirective>
      {columnInfo.map((column, i) => {
        if(column.field === 'displayName'){
          //Render team title as a link to the team
          return <ColumnDirective {...column} key={`column_${uniqid()}_${i}`} template={(props:ITeam) => {
            const { teamId, displayName, webUrl} = props;
            return <a href={webUrl} target="_blank" rel="nofollow" title="Click to go to team" key={`link_to_team_${teamId}`}>
              {displayName}
            </a>;
          }}/>
        }

        if(column.field === 'spaceUsed'){
          //Format byte count
          return <ColumnDirective {...column} key={`column_${uniqid()}_${i}`} template={(props:ITeam) => {
            const { spaceUsed } = props;
            return <span>{Utilities.convertToStorageSizeString(spaceUsed)}</span>;
          }}/>
        }

        if(column.field === 'hasPrivateChannels'){
          //Format byte count
          return <ColumnDirective {...column} key={`column_${uniqid()}_${i}`} template={(props:ITeam) => {
            const { hasPrivateChannels } = props;
            if(hasPrivateChannels){
              return <span>Yes</span>
            }else{
              return <span>No</span>
            }
          }}/>
        }

        return <ColumnDirective {...column} key={`column_${uniqid()}_${i}`}/>
      })}
    </ColumnsDirective>);
  }

  private renderPlaceholder(): React.ReactElement {
    return <div className="justify-content-center text-center">
      <h1 className="text-gray"><i className="fa fa-info-circle"></i></h1>
      <h3 className="text-gray">No data available.</h3>
    </div>
  }

  private renderNoItems(): React.ReactElement {
    return <div className="justify-content-center text-center">
      <h1 className="text-gray"><i className="fa fa-thumbs-up"></i></h1>
      <h3 className="text-gray">Nothing to show here.</h3>
    </div>
  }

  private renderPagination(): React.ReactElement {
    if (this.props.enablePaging && this.props.totalPages && this.props.currentPage) {
      return (<Pagination pageCount={this.props.totalPages} currentPage={this.props.currentPage} onPageChange={this.props.onPageChange} />);
    }
    else {
      return <></>
    }
  }

  private onTitleClick(id: string) {
    if (this.props.onTitleClick) {
      this.props.onTitleClick(id);
    }
  }

  private dataBound() {
    if (this.grid && this.props.resizeable) {
      this.grid.autoFitColumns(['displayName']);
    }
  }
}
