export default class Utilities {
    public static Deserialize(data: string): any {
        return JSON.parse(data, Utilities.ReviveDateTime);
    }

    private static ReviveDateTime(key: any, value: any): any {
        if (typeof value === 'string') {
            let a = /\/Date\((\d*)\)\//.exec(value);
            if (a) {
                return new Date(+a[1]);
            }
        }
        return value;
    }

    public static convertToStorageSizeString(bytesValue?: number): string {
        let bytesPerMB = 1048576;
        if (bytesValue) {
            let value = (bytesValue / bytesPerMB);
            if(value > 1000){
                //convert to gigabytes
                return `${(value/1000).toFixed(2)} GB`;
            }
            return `${value.toFixed(2)} MB`;
        }
        return "0";
    }

    public static getMonths(): string[] {
        return [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December'
        ];
    }
}