import React, { Component } from 'react';
import { RouteComponentProps } from "react-router";
import * as QueryString from "query-string";
import uniqid from 'uniqid';

import { IProductService } from "../services/IProductService";
import { IProductInfo } from "../models/ProductInfo";

import { ItemStorage } from "../itemstorage";
import { Constants } from "../constants";

import Loading from '../components/Loading';
import Alert from '../components/Alert';

interface IBaseProps extends RouteComponentProps<{}> {
    productService: IProductService;
}

interface IProductInfoState {
    loading: boolean;
    productInfo?: IProductInfo;
    errors?: string[];
}

export class ProductInfo extends Component<IBaseProps, IProductInfoState> {
    constructor(props: IBaseProps) {
        super(props);

        this.state = {
            loading: true
        };
    }

    async componentDidMount() {
        // clear previous values
        ItemStorage.clearStorageItem(Constants.productInfo);
        ItemStorage.clearStorageItem(Constants.uniqueId);
        ItemStorage.clearStorageItem(Constants.page);
        ItemStorage.clearStorageItem(Constants.site);

        const params = QueryString.parse(this.props.location.search);
        if (params.product && params.uid) {
            try {
                var productInfo = await this.props.productService.getProductInfo(params.product as string);
                var scopes: string[] = [];

                if (productInfo.permissions && productInfo.permissions.length > 0)
                    productInfo.permissions.filter(x => x.requiresConsent).forEach((item) => {
                        scopes.push(item.scope);
                    });

                ItemStorage.setStorageItem(Constants.productInfo, productInfo);
                ItemStorage.setStorageItem(Constants.uniqueId, params.uid);

                // set the page in storage for reference it later
                if (params.page) {
                    ItemStorage.setStorageItem(Constants.page, params.page);
                }

                if (params.site) {
                    // if site parameter is present, then redirect directly to the form
                    ItemStorage.setStorageItem(Constants.site, params.site);

                    this.props.history.push("/form");
                }

                this.setState({
                    loading: false,
                    productInfo: productInfo
                });
            }
            catch (err) {
                console.log(err);
            }
        } else {
            console.log('Product not specified, redirecting to products page...');
            window.location.href = 'https://www.konsolute.com/products';
        }
    }

    public render() {
        return <>
            {
                this.state.loading ?
                    <Loading /> : this.renderInfo()
            }
        </>;
    }

    private renderErrors(): React.ReactNode | void {
        if (this.state.errors && (this.state.errors.length > 0)) {
            return this.state.errors.map((error, i) => {
                return <Alert message={error} />;
            });
        }
    }

    private renderProductRequirements(): React.ReactNode | void {
        const { productInfo } = this.state;
        if (productInfo && productInfo.permissions && (productInfo.permissions.length > 0)) {
            let contents = (<div className="card card-plain no-margin">
                <ul className="list-group">
                    {productInfo.permissions.map((perm, i) => {
                        return (<li className="list-group-item list-item-break" key={`perm_${i}`}>
                            <h5 className="m-0 title">{perm.displayName}</h5>
                            <p className="text-muted bolder">{perm.description}</p>
                        </li>);
                    })}
                </ul>
            </div>);
            return (<div className="card">
                <div className="card-body">
                    <h3 className="title">Permission Requirements</h3>
                    <hr />
                    {contents}
                </div>
            </div>);
        }

    }

    private renderProductPrerequisites(): React.ReactNode | void {
        const { productInfo } = this.state;
        if (productInfo && productInfo.prerequisites && (productInfo.prerequisites.length > 0)) {
            let contents = (<div className="card card-plain no-margin">
                <ul className="list-group">
                    {productInfo.prerequisites.map((pre, i) => {
                        return (<li className="list-group-item list-item-break" key={`pre_${i}`}>
                            <h5 className="m-0 title">{pre.displayName}</h5>
                        </li>);
                    })}
                </ul>
            </div>);
            return (<div className="card"><div className="card-body">
                <h3 className="title">Prerequisites</h3>
                <hr />
                {contents}
            </div>
            </div>);
        }
    }

    private renderProductFeatures(): React.ReactNode | void {
        if (this.state.productInfo && this.state.productInfo.features)
            return (this.state.productInfo.features.map((feature, i) => {
                return (<div className="info info-horizontal" key={`Feature_${i}`}>
                    <div className="icon icon-info">
                        <i className="material-icons">stars</i>
                    </div>
                    <div className="description">
                        <h5 className="info-title">{feature.name}</h5>
                        <p className="text-dark bolder">{feature.description}</p>
                    </div>
                </div>);
            }));
    }

    private renderInstallButton(): React.ReactElement {
        let label: string = "Install to tenancy";
        const { productInfo } = this.state;

        if (productInfo && productInfo.productInfoInstallButtonText) {
            label = productInfo.productInfoInstallButtonText;
        }
        return <button className="btn btn-info btn-lg btn-install" value={label} onClick={() => { this.props.history.push("/form") }}><i className="material-icons">cloud_download</i>&nbsp;{label} <div className="ripple-container"></div></button>
    }

    private renderProductDescription(): React.ReactFragment | void {
        const { productInfo } = this.state;

        if (productInfo && productInfo.description) {
            let formatted = productInfo.description.split('\n');

            return (<>
                <h2 className="title">Product Description</h2>
                <div className="card-description text-dark bolder">
                    { formatted.map((section, i) => <p key={`description_${i}_${uniqid()}`}>{section}</p>) }
                </div>
            </>);
        }
    }

    private renderProductLogo(): React.ReactFragment | void {
        const { productInfo } = this.state;

        if (productInfo && productInfo.logo) {
        return (<div className="row">
                <div className="col-3 col-md-6 col-lg-4 mr-auto">
                    <img src={productInfo.logo} alt="Product Logo" className="img-fluid clip-fix" />
                </div>
            </div>);
        }
    }

    private renderInfo(): React.ReactFragment {
        return (<>
            <div className="page-header section-header header-filter clear-filter white-bg" >
                <div className="container">
                    <div className="row">
                        <div className="col-md-4 col-lg-5">
                           {this.renderProductLogo()}
                            <div className="row">
                                <div className="col-12">
                                    <h1 className="title konsblue">{this.state.productInfo?.displayName}</h1>
                                    <h4 className="text-dark bolder">{this.state.productInfo?.tagLine}</h4>
                                    <br />
                                    {this.renderInstallButton()}
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8 col-lg-7 vcenter"><img className="img-fluid" src={this.state.productInfo?.headerImage} alt="A screenshot of the product" /> </div>
                    </div>
                </div>
            </div>

            <div className="main main-raised">
                <div className="container">
                    <div className="features-3">
                        <div className="row">
                            <div className="col-md-6">
                                {this.renderProductDescription()}
                                {this.renderProductFeatures()}

                            </div>
                            <div className="col-md-5 ml-auto">
                                {this.renderProductPrerequisites()}
                                {this.renderProductRequirements()}
                                <div className="row">
                                    <div className="col-12 ml-auto mr-auto">
                                        {((this.state.productInfo) && this.state.productInfo?.productImages.length > 0) && this.renderCarousel()}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="section text-center">
                        <h2 className="text-muted">Additional support is available via our <a className="text-info" href="http://www.konsolute.com/support/">Support Portal</a></h2>
                        <div className="row">
                            <div className="col-12">
                                <a href={this.state.productInfo?.supportLink} className="btn btn-round btn-default btn-lg btn-outline btn-bolder"><i className="material-icons">library_books</i>&nbsp;Knowledge Base</a>
                                <a href='mailto:support@konsolute.com' className="btn btn-round btn-default btn-lg btn-outline btn-bolder"><i className="material-icons">email</i>&nbsp;Contact Us</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="blogs-2 section-dark text-center d-none" id="blogs-2">
                <div className="container">
                    <div className="row">
                        <div className="col-md-10 ml-auto mr-auto">
                            <h2 className="title">Our other products</h2>
                            <br />
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="card card-blog">
                                        <div className="card-header card-header-image">
                                            <a href="https://www.konsolute.com/products/auto-classifier">
                                                <img className="img img-raised" src="./assets/img/konsolute-products/AC.jpg" alt="Auto classifier logo" />
                                            </a>
                                            <div className="colored-shadow"></div></div>
                                        <div className="card-body">
                                            <h6 className="card-category text-info">TAG CONTENT</h6>
                                            <h4 className="card-title">
                                                <a href="https://www.konsolute.com/products/auto-classifier">Auto Classifier</a>
                                            </h4>
                                            <p className="card-description">
                                                Auto Classifier’s hyper-intuitive cognitive capabilities intelligently extracts keywords by understanding the semantic context of your content and eliminates the effort required for end-users to add metadata manually to their content therefore, allowing your workforce to focus at the task in hand
                      <a href="https://www.konsolute.com/products/auto-classifier"> View More </a>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="card card-blog">
                                        <div className="card-header card-header-image">
                                            <a href="https://www.konsolute.com/products/onboard">
                                                <img className="img img-raised" src="./assets/img/konsolute-products/onboard.jpg" alt="Onboard logo" />
                                            </a>
                                            <div className="colored-shadow"></div></div>
                                        <div className="card-body ">
                                            <h6 className="card-category text-success">
                                                NEW EMPLOYEES
                      </h6>
                                            <h4 className="card-title">
                                                <a href="https://www.konsolute.com/products/onboard">Onboard</a>
                                            </h4>
                                            <p className="card-description">
                                                Onboard is a revolutionary new hire onboarding platform built for organisations consuming Microsoft 365. Onboard allows you to define your organisation’s onboarding experience and further refine this by allowing hiring managers and departments to create specific onboarding experiences.
                      <a href="https://www.konsolute.com/products/onboard"> View More </a>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="card card-blog">
                                        <div className="card-header card-header-image">
                                            <a href="https://www.konsolute.com/products/virtual-academy/">
                                                <img className="img img-raised" src="./assets/img/konsolute-products/va.jpg" alt="Virtual academy logo" />
                                            </a>
                                            <div className="colored-shadow" ></div></div>
                                        <div className="card-body ">
                                            <h6 className="card-category text-danger">
                                                Training
                      </h6>
                                            <h4 className="card-title">
                                                <a href="https://www.konsolute.com/products/virtual-academy/">Virtual Academy</a>
                                            </h4>
                                            <p className="card-description">
                                                Virtual Academy is a Learning Management System (LMS) which is user friendly with the idea of making training and informing your organisation about Microsoft 365 products easy and accessible, tailoring to all your organisation’s needs, to give you the best possible training experience when educating your workforce.
                      <a href="https://www.konsolute.com/products/virtual-academy/"> View More </a>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {this.renderErrors()}
        </>);
    }



    private renderCarousel(): React.ReactFragment {

        return (<div id="productCarousel" className="carousel slide" data-ride="carousel">
            <ol className="carousel-indicators">
                {
                    this.state.productInfo?.productImages.map((image, i) => {
                        if (i === 0) {
                            return (<li data-target="#productCarousel" data-slide-to="0" className="active" key={`productimgindicator_${i}`}></li>);
                        } else {
                            return (<li data-target="#productCarousel" data-slide-to={i} key={`productimgindicator_${i}`}></li>);
                        }
                        /*return (<div className="card">
                            <img className="card-img-top" src={image} alt={`${this.state.productInfo?.name} screenshot ${i}`} title={`${this.state.productInfo?.name} screenshot ${i}`} />
                        </div>);*/
                    })
                }
            </ol>
            <div className="carousel-inner">
                {
                    this.state.productInfo?.productImages.map((image, i) => {
                        if (i === 0) {
                            return (<div className="carousel-item active" key={`productimg_${i}`}>
                                <img className="d-block w-100" src={image} alt={`${this.state.productInfo?.name} screenshot ${i}`} title={`${this.state.productInfo?.name} screenshot ${i}`} />
                            </div>);
                        } else {
                            return (<div className="carousel-item" key={`productimg_${i}`}>
                                <img className="d-block w-100" src={image} alt={`${this.state.productInfo?.name} screenshot ${i}`} title={`${this.state.productInfo?.name} screenshot ${i}`} />
                            </div>);
                        }
                    })
                }
            </div>
            <a className="carousel-control-prev" href="#productCarousel" role="button" data-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="sr-only">Previous</span>
            </a>
            <a className="carousel-control-next" href="#productCarousel" role="button" data-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="sr-only">Next</span>
            </a>
        </div>);
    }

}