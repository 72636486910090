import React, { Component } from 'react';
import { RouteComponentProps } from "react-router";
import AuthProvider from "../auth/AuthProvider";
import { IProductService } from '../services/IProductService';
import { IProductInfo } from '../models/ProductInfo';
import { ItemStorage } from '../itemstorage';
import { Constants } from '../constants';
import { _imageRestrictions } from '../settings/Images';
import { PrerequisitesService, IPrerequisitesValidationResult } from "../services/PrerequisitesService";

import Loading from '../components/Loading';
import Alert from '../components/Alert';

import { ValidationService } from "../services/ValidationService";

import Form, { UiSchema, Widget, WidgetProps, IChangeEvent, FieldProps, AjvError, ISubmitEvent, Field } from "@rjsf/core";
import { JSONSchema7 } from 'json-schema';
import { TenantService } from '../services/TenantService';

interface IBaseProps extends RouteComponentProps<{}> {
    authProvider: AuthProvider;
    productService: IProductService;
}

interface IProductFormState {
    loading: boolean;
    productInfo?: IProductInfo;
    data?: any;
    errors?: any;
    base64Image?: string;
    validationSchema?: { [fieldName: string]: string };
    prerequisitesValidationResult?: IPrerequisitesValidationResult[];
    tenant?: string;
    extraErrors: {
        [key: string]: { __errors: string[] }
    };
}

export class ProductForm extends Component<IBaseProps, IProductFormState> {

    _tenantName: string = "";
    private fileReader: FileReader;
    private uploadField: HTMLInputElement | null;

    constructor(props: IBaseProps) {
        super(props);

        this.state = {
            loading: true,
            extraErrors: {}
        };

        this.onChange = this.onChange.bind(this);
        this.onInstall = this.onInstall.bind(this);
        this.SiteCollectionInput = this.SiteCollectionInput.bind(this);
        this.SiteLogoPicker = this.SiteLogoPicker.bind(this);
        this.fileChosen = this.fileChosen.bind(this);
        this.siteLogoLoader = this.siteLogoLoader.bind(this);
        this.chooseFile = this.chooseFile.bind(this);

        this.fileReader = new FileReader();
        this.fileReader.addEventListener("loadend", this.siteLogoLoader);
        this.uploadField = null;

    }

    async componentDidMount() {
        var productInfo = ItemStorage.getStorageItem(Constants.productInfo) as IProductInfo;

        var scopes: string[] = [];

        productInfo.permissions.filter(x => x.requiresConsent).forEach((item) => {
            scopes.push(item.scope);
        });

        this.props.authProvider.ensureLogin(scopes);

        if (productInfo) {

            const prerequisitesService = new PrerequisitesService(this.props.authProvider, productInfo.prerequisites);
            const result = await prerequisitesService.validate();

            let validationSchema: { [fieldName: string]: string } = {};

            if (productInfo.validators) {
                await ValidationService.init(this.props.authProvider, productInfo.validators);
                productInfo.validators.forEach((val) => {
                    validationSchema[val] = ValidationService.validator(val);
                });
            }

            const tenantName: string = await TenantService.getTenantName(this.props.authProvider);

            this.setState({
                loading: false,
                productInfo: productInfo,
                data: {},
                validationSchema: validationSchema,
                prerequisitesValidationResult: result,
                tenant: tenantName
            });
        } else {
            console.log('Product not specified, redirecting to products page...');
            window.location.href = 'https://www.konsolute.com/products';
        }
    }

    public render(): JSX.Element {
        return <>
            {
                this.state.loading ?
                    <Loading /> :
                    this.renderBody()
            }

        </>;
    }

    private renderBody(): React.ReactFragment {

        const schema: JSONSchema7 = this.state.productInfo?.objectSchema || {};

        const uiSchema: UiSchema = this.state.productInfo?.uiSchema || {};

        const widgets = {
            CheckboxWidget: this.CustomCheckbox,
            TextWidget: this.CustomStringInput,
            "twitter": this.CustomTwitterInput,
            "siteCollectionUrl": this.SiteCollectionInput,
            "colourPicker": this.CustomColourPicker,
            "logoPicker": this.SiteLogoPicker,
            CheckboxesWidget: this.CustomCheckboxGroup,
            "customSlider": this.CustomSlider
        };

        return (<>
            <div className="page-header page-header-short clear-filter white-bg">
                <div className="container">
                    {this.renderProductLogo()}
                    <div className="row">
                        <div className="col-md-8 ml-auto mr-auto">
                            <div className="brand text-center">
                                <h1 className="text-dark">Okay, let's install: <strong className="title konsblue">{this.state.productInfo?.displayName}</strong></h1>
                                <h3 className="title text-dark">We'll need some information from you to get this up and running.</h3>
                                <h3 className="text-muted"><i className="material-icons display-3">arrow_circle_down</i></h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="main main-raised">
                <div className="container">
                    <div className="section">

                        <div className="row">
                            <div className="col-sm-12 col-md-8 col-lg-6 ">

                                <div className="card" >
                                    <div className="card-body">
                                        {
                                            this.state.prerequisitesValidationResult?.filter(x => !x.isValid).length == 0 ?
                                                <>
                                                    <h2 className="title">We're nearly there!</h2>
                                                    <p className="details-desc">Before we begin to install the selected app, we need to capture some additional information. <strong>Please ensure that all mandatory fields completed.</strong><br /></p>

                                                    <Form
                                                        schema={schema}
                                                        uiSchema={uiSchema}
                                                        formData={this.state.data}
                                                        onSubmit={this.onInstall}
                                                        onChange={this.onChange}
                                                        widgets={widgets}
                                                        customFormats={this.state.validationSchema}
                                                        transformErrors={this.transformErrors}
                                                        showErrorList={false}
                                                        extraErrors={this.state.extraErrors}
                                                        className={`productForm`}
                                                    >

                                                        <div className="row">
                                                            <div className="col-12 text-center">
                                                                <button type='button' className="btn btn-round btn-danger btn-lg btn-link" onClick={() => { this.props.history.goBack() }}><i className="material-icons">close</i>&nbsp;Cancel</button>
                                                                {this.renderInstallButton()}
                                                                <p className="description small text-bold">By clicking on 'Install' you agree to the terms set out in the <a href="https://www.konsolute.com/products/terms-of-usage/" target="_blank">Terms of Usage</a></p>
                                                            </div>
                                                        </div>
                                                    </Form>
                                                </> :
                                                <>
                                                    <h2 className="title">Prerequisites not met</h2>
                                                    {
                                                        this.state.prerequisitesValidationResult?.map((result, i) => {
                                                            return <p className="details-desc text-danger" key={`prereq_${i}`}><strong>{result.errorMessage}</strong><br /></p>
                                                        })
                                                    }
                                                </>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-4 col-lg-6 text-center">
                                <div className="card" >
                                    <img className="card-img-top" src={this.state.productInfo?.productImages[0]} alt="Preview" />
                                    {/* <div className="card-body">
                                        <p className="card-text">Preview</p>
                                    </div> */}
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            {
                (this.state.errors && (this.state.errors.length > 0)) &&
                this.state.errors.map((error: any, i: number) => {
                    return <Alert message={error} key={`alert_${i}`} />;
                })
            }
        </>);
    }

    private onChange(ev: IChangeEvent<any>): void {
        this.setState({
            ...this.state,
            data: ev.formData
        });
    }

    private pushError(message: string, fieldId: string) {
        let errors = this.state.extraErrors;
        this.setState({
            extraErrors: {
                ...errors,
                [fieldId]: {
                    __errors: [message]
                }
            }
        });
    }

    private renderProductLogo(): React.ReactFragment | void {
        const { productInfo } = this.state;

        if (productInfo && productInfo.logo) {
        return (  <div className="row">
        <div className="col-4 col-sm-3 col-md-2 ml-auto mr-auto">
            <img src={productInfo.logo} alt="Product Logo" className="img-fluid" />
        </div>
    </div>);
        }
    }

    private renderInstallButton():React.ReactElement{
        let label:string = "Install";
        const { productInfo } = this.state;

        if(productInfo && productInfo.productFormInstallButtonText){
            label = productInfo.productFormInstallButtonText;
        }
        return <button type='submit' className="btn btn-round btn-success btn-lg"><i className="material-icons">cloud_download</i>&nbsp;{label}</button>
    }

    private async onInstall(formObj: any) {
        if (this.state.productInfo) {
            if (!formObj.errors || (formObj.errors && formObj.errors.length == 0)) {

                var uniqueId = ItemStorage.getStorageItem(Constants.uniqueId) as string;
                this.setState({
                    ...this.state,
                    loading: true
                });

                let submittedData: any = this.state.data;

                //Add in base64image
                if (this.state.productInfo && this.state.productInfo.objectSchema.properties && this.state.productInfo.objectSchema.properties.siteLogo) {
                    if (this.state.base64Image && (this.state.base64Image.length > 0)) {
                        submittedData.siteLogo = this.state.base64Image;
                    } else {
                        submittedData.siteLogo = "";
                    }
                }

                //Prepend site collection
                const { siteUrl, siteurl } = formObj.formData;
                if (siteUrl) {
                    submittedData.siteUrl = encodeURI(`https:\/\/${this.state.tenant}.sharepoint.com\/sites\/${siteUrl}`);
                } else if (siteurl) {
                    submittedData.siteurl = encodeURI(`https:\/\/${this.state.tenant}.sharepoint.com\/sites\/${siteurl}`);
                }

                var site = ItemStorage.getStorageItem(Constants.site) as string;
                var page = ItemStorage.getStorageItem(Constants.page) as string;
                try {
                    var accessToken = await this.props.authProvider.getApiAccessToken();
                    await this.props.productService.install(
                        this.state.productInfo?.name,
                        uniqueId,
                        submittedData,
                        accessToken,
                        site,
                        page);
                    this.setState({
                        ...this.state,
                        loading: false
                    });

                    this.props.history.push('/complete');
                } catch (err) {
                    console.log(err);
                    this.setState({
                        ...this.state,
                        loading: false,
                        errors: [
                            err
                        ]
                    });
                }
            }
        }
    }

    private transformErrors(errors: AjvError[]) {
        return errors.map(error => {
            if (error.name === "format") {
                if (error.params.format && (error.params.format == "sitecollection")) {
                    error.message = `Please ensure your site collection URL begins with: '${this.state.tenant}'`;
                }
            }
            return error;
        });
    }

    /* Custom react-jsonschema-form widgets and fields - TODO put into components*/

    private CustomCheckboxGroup(props: WidgetProps) {
        const selectValue = (value: any, selected: any, all: any) => {
            const at = all.indexOf(value);
            const updated = selected.slice(0, at).concat(value, selected.slice(at));

            // As inserting values at predefined index positions doesn't work with empty
            // arrays, we need to reorder the updated selection to match the initial order
            return updated.sort((a: any, b: any) => all.indexOf(a) > all.indexOf(b));
        };

        const deselectValue = (value: any, selected: any) => {
            return selected.filter((v: any) => v !== value);
        };

        const { id, value, onChange, disabled, readonly, autofocus, options} = props;
        const { enumOptions, enumDisabled, } = options;
        const _onChange = (option: any) => ({ target: { checked }}: React.ChangeEvent<HTMLInputElement>) => {
            const all = (enumOptions as any).map(({ value }: any) => value);

            if (checked) {
                onChange(selectValue(option.value, value, all));
            } else {
                onChange(deselectValue(option.value, value));
            }
        };

        return (enumOptions as any).map((option: any, index: number) => {

            const checked = value.indexOf(option.value) !== -1;
            const itemDisabled = enumDisabled && (enumDisabled as any).indexOf(option.value) != -1;
            const checkbox = (
                <div className="form-check" key={`cb_${index}`}>
                    <label className="form-check-label">
                        <input className="form-check-input" id={`${id}_${index}`} type="checkbox" checked={checked} disabled={disabled || itemDisabled || readonly}
                            autoFocus={autofocus && index === 0}
                            onChange={_onChange(option)} />
                        {checked ? <div className="text-info">{option.label}</div> : <div className="text-muted">{option.label}</div>}
                        <span className="form-check-sign">
                            <span className="check"></span>
                        </span>
                    </label>
                </div>
            );
            return checkbox;
        });
    }

    private CustomCheckbox(props: WidgetProps) {
        const { id, value, onChange, onClick, disabled, label, rawErrors, readonly, required, autofocus, schema, uiSchema } = props;
        let currentValue: boolean = false;
        if (value !== undefined) {
            currentValue = value;
        }
        return (<div className="form-check">
            <label className="form-check-label">
                <input className="form-check-input" id={id} type="checkbox" checked={currentValue} onChange={(event) => onChange(event.target.checked)} onClick={onClick} disabled={disabled} readOnly={readonly} required={required} autoFocus={autofocus} />
                {value ? <div className="text-info">{label}</div> : <div className="text-muted">{label}</div>}
                <span className="form-check-sign">
                    <span className="check"></span>
                </span>
            </label>
            {/* {(schema && schema.description) && <small className="form-text text-muted">{schema.description}</small>}
            {(uiSchema && uiSchema['ui:description']) && <small className="form-text text-muted">{uiSchema['ui:description']}</small>} */}
            {/* {rawErrors && <small className="form-text text-danger">{rawErrors.map((err,i) => <span key={`ErrCheckbox_${i}`}>{err}</span>)}</small>} */}
        </div>);
    }

    private CustomSlider(props: WidgetProps) {
        const { id, value, onChange, onClick, disabled, label, rawErrors, readonly, required, autofocus, schema, uiSchema, className } = props;
        let description: string = "Please adjust the Tone slider";
        if (value) {
            switch (value) {
                case 1: description = "Very informal"; break;
                case 2: description = "Informal"; break;
                case 3: description = "Semi-formal"; break;
                case 4: description = "Formal"; break;
                case 5: description = "Very formal"; break;
                default: description = "Please adjust the Tone slider";
            }
        }
        return (<div>
            <input className="form-control" type="range" id={id} required={required} min={schema.minimum} max={schema.maximum} value={value} onChange={(event) => onChange(event.target.value)} />
            {description}
        </div>);
    }

    private CustomStringInput(props: WidgetProps) {
        const { id, value, onChange, onClick, label, rawErrors, disabled, placeholder, readonly, required, autofocus, schema, uiSchema } = props;
        let currentValue: string = "";
        if (value && (value.length > 0)) {
            currentValue = value;
        }
        return (<div className="form-group">
            <input type="text" className="form-control" id={id} value={currentValue} onChange={(event) => onChange(event.target.value)} onClick={onClick} disabled={disabled} placeholder={placeholder} readOnly={readonly} required={required} autoFocus={autofocus} />
            {/* {(schema && schema.description) && <small className="form-text text-muted">{schema.description}</small>}
            {(uiSchema && uiSchema['ui:description']) && <small className="form-text text-muted">{uiSchema['ui:description']}</small>} */}
            {/* {rawErrors && <small className="form-text text-danger">{rawErrors.map((err,i) => <span key={`ErrString_${i}`}>{err}</span>)}</small>} */}
        </div>);
    }

    private CustomTwitterInput(props: WidgetProps) {
        const { id, value, onChange, onClick, rawErrors, disabled, placeholder, readonly, required, autofocus, schema, uiSchema } = props;
        let currentValue: string = "";
        if (value && (value.length > 0)) {
            currentValue = value;
        }
        return (<div className="input-group">
            <div className="input-group-prepend">
                <span className="input-group-text">
                    <i className="material-icons">alternate_email</i>
                </span>
            </div>
            <input type="text" className="form-control" id={id} value={currentValue} onChange={(event) => onChange(event.target.value)} onClick={onClick} disabled={disabled} placeholder={placeholder} readOnly={readonly} required={required} autoFocus={autofocus} />
            {/* {(schema && schema.description) && <small className="form-text text-muted">{schema.description}</small>}
            {(uiSchema && uiSchema['ui:description']) && <small className="form-text text-muted">{uiSchema['ui:description']}</small>} */}
            {/* {rawErrors && <small className="form-text text-danger">{rawErrors.map((err,i) => <span key={`ErrTwitter_${i}`}>{err}</span>)}</small>} */}
        </div>);
    }

    private SiteCollectionInput(props: WidgetProps) {
        const { id, value, onChange, onClick, rawErrors, disabled, placeholder, readonly, required, autofocus, schema, uiSchema } = props;
        let currentValue: string = "";
        if (value && (value.length > 0)) {
            currentValue = value;
        }
        return (<div className="input-group">
            <div className="input-group-prepend">
                <span className="input-group-text" id="sitecollectionurl-label">
                    {`https:\/\/${this.state.tenant}.sharepoint.com\/sites\/`}
                </span>
            </div>
            <input type="text" className="form-control" id={id} value={currentValue} onChange={(event) => onChange(event.target.value)} onClick={onClick} disabled={disabled} placeholder={placeholder} readOnly={readonly} required={required} autoFocus={autofocus} />
            {/* {(schema && schema.description) && <small className="form-text text-muted">{schema.description}</small>}
            {(uiSchema && uiSchema['ui:description']) && <small className="form-text text-muted">{uiSchema['ui:description']}</small>} */}
            {/* {rawErrors && <small className="form-text text-danger">{rawErrors.map((err,i) => <span key={`ErrTwitter_${i}`}>{err}</span>)}</small>} */}
        </div>);
    }

    private CustomColourPicker(props: WidgetProps) {
        const { id, value, onChange, onClick, rawErrors, disabled, placeholder, readonly, required, autofocus, schema, uiSchema } = props;
        const options: any = props.options.enumOptions;
        return (<div className="form-group">
            <select id={id} className={`form-control colour-picker ${value && value.toLowerCase()}`} value={value} onChange={(event) => onChange(event.target.value)} onClick={onClick} disabled={disabled} placeholder={placeholder} required={required} autoFocus={autofocus}>
                <option value=""></option>
                {
                    (options && (options.length > 0)) && options.map((opt: any, idx: number) => {
                        return <option key={`ColorOpt_${idx}`} value={opt.value} className={`${opt.value.toLowerCase()}`}>{opt.label}</option>
                    })
                }
            </select>
            {/* {(schema && schema.description) && <small className="form-text text-muted">{schema.description}</small>}
            {(uiSchema && uiSchema['ui:description']) && <small className="form-text text-muted">{uiSchema['ui:description']}</small>} */}
            {/* {rawErrors && <small className="form-text text-danger">{rawErrors.map((err,i) => <span key={`ErrTwitter_${i}`}>{err}</span>)}</small>} */}
        </div>);
    }

    private SiteLogoPicker(props: WidgetProps) {
        const { id, value, onChange, onClick, disabled, label, multiple, placeholder, readonly, required, autofocus, rawErrors, options } = props;
        const acceptOptions: string = options.accept?.toString() ?? `*`;
        let currentValue: string = "";
        if (value && (value.length > 0)) {
            currentValue = value;
        }
        return (<div className="form-group form-file-upload form-file-multiple">
            <input type="file" className="inputFileHidden" id={id} ref={field => { this.uploadField = field }} onClick={onClick} onChange={(event) => this.fileChosen(event, required, id, onChange)} multiple={multiple} accept={acceptOptions} disabled={disabled} placeholder={placeholder} readOnly={readonly} required={required} autoFocus={autofocus} />
            <div className="input-group">
                <input type="text" className="form-control inputFileVisible" placeholder="Single File" value={value} onClick={this.chooseFile} />
                <span className="input-group-btn">
                    <button type="button" className="btn btn-fab btn-round btn-primary" onClick={this.chooseFile} >
                        <i className="material-icons">attach_file</i>
                    </button>
                </span>
            </div>
            {/* {(schema && schema.description) && <small className="form-text text-muted">{schema.description}</small>}
                {(uiSchema && uiSchema['ui:description']) && <small className="form-text text-muted">{uiSchema['ui:description']}</small>} 
                {rawErrors && <small className="form-text text-danger">{rawErrors.map((err, i) => <span key={`ErrCheckbox_${i}`}>{err}</span>)}</small>} */}
        </div>);
    }

    private chooseFile(): void {
        if (this.uploadField) {
            this.uploadField.click();
        }
    }

    private fileChosen(ev: React.FormEvent<HTMLInputElement>, required: boolean, fieldId: string, parentEvent: any): void {
        const filecontrol = ev.currentTarget;
        if (filecontrol.files && (filecontrol.files.length > 0)) {
            var chosenFile = filecontrol.files[0];
            this.fileReader.readAsDataURL(chosenFile);
            this.setState(prevState => ({
                ...prevState,
                data: {
                    ...prevState.data,
                    siteLogo: chosenFile.name
                }
            }));
        } else {
            //Reset field
            this.setState(prevState => ({
                ...prevState,
                data: {
                    ...prevState.data,
                    siteLogo: "",
                },
                base64Image: "",
                extraErrors: {
                    ...prevState.extraErrors,
                    [fieldId]: { __errors: [(required ? "Please upload a site logo" : "")] }
                }
            }));
        }
        parentEvent(ev);
    }

    private imageSizeValid(imgData: string): Promise<boolean> {
        return new Promise<boolean>((res, rej) => {
            let image = new Image();
            image.onload = (ev) => {
                if (image.width <= _imageRestrictions.minWidth || image.height <= _imageRestrictions.minHeight)
                    res(false);
                else
                    res(true);
            }
            image.src = imgData;
        });
    }

    private async siteLogoLoader(evt: ProgressEvent<FileReader>): Promise<void> {
        var fieldId: string = "siteLogo";
        var result = this.fileReader.result as string;
        // the total length is in bytes.
        // if it's any bigger, ignore it.
        if (evt.total / 1024 > _imageRestrictions.maxKb) {
            //SendAlertNotification.Notification(`Logo file size is too big (${_imageRestrictions.maxKb}k or less).`);
            this.pushError(`Logo file size is too big (${_imageRestrictions.maxKb}k or less).`, fieldId);
            return;
        }

        let validSize = await this.imageSizeValid(result);

        if (!validSize) {
            var h = _imageRestrictions.minHeight;
            var w = _imageRestrictions.minWidth;
            this.pushError(`Logo dimensions must be at least ${w}x${h}.`, fieldId);
            return;
        }

        this.setState(prevState => ({
            ...prevState,
            base64Image: result,
            extraErrors: {
                ...prevState.extraErrors,
                [fieldId]: {
                    __errors: []
                }
            }
        }));
    }


}