import * as React from 'react';
import CounterCard from '../../../components/Report/widgets/CounterCard';
import TableCard from '../../../components/Report/widgets/TableCard';
import ITableItem from '../../../models/Report/ITableItem';
import { IExternalAppsSummary, ITeamsReport } from '../../../models/Report/ITabModels';
import { ReportStrings } from '../../../settings/Report/ReportSettings';
import ClampLines from 'react-clamp-lines';
import uniqid from 'uniqid';

export interface IAppsTabProps {
  topExtAppsLimit: number;
  onTeamClick(teamId: string): void;
  loading: boolean;
  externalApps?: IExternalAppsSummary;
  teamsWithExternalApps?: ITeamsReport;
  teamsTablePageChange(pageNumber: number): void;
}

export interface IAppsTabState {
  sampleTableItems: ITableItem[];
}

export default class AppsTab extends React.PureComponent<IAppsTabProps, IAppsTabState> {
  constructor(props: IAppsTabProps) {
    super(props);

    this.state = {
      sampleTableItems: [{
        column: 'Name',
        displayName: 'Team One',
        url: 'https://www.example.com'
      }],
    };
  }

  public render() {
    const { externalApps, teamsWithExternalApps, teamsTablePageChange } = this.props;
    return (
      <div>
        <h4 className="title">{ReportStrings.apps.title}</h4>
        <div className="row">
          <div className="col-sm-12 text-center">
            <CounterCard title={ReportStrings.apps.appsCounter.title} value={externalApps?.uniqueExternalAppsCount} icon="assignment_late" colour="info">
              <div className="stats">
                <i className="material-icons text-info">privacy_tip</i>
                <ClampLines
                  id={`countercard_${uniqid()}`}
                  text={ReportStrings.apps.appsCounter.description}
                  lines={3}
                  ellipsis="..."
                  buttons={true}
                  className="clamped-text"
                  moreText={'Read more'}
                  lessText={'Read less'} />
              </div>
            </CounterCard>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-6 col-sm-12">
            <TableCard
              id={`all_external_apps`}
              title={ReportStrings.apps.appsList.title}
              description={ReportStrings.apps.appsList.description}
              colour="warning"
              items={externalApps?.topExternalAppAggregate}
              onTitleClick={this.props.onTeamClick}
              enablePaging={false}
              columnInfo={[
                { headerText: "App Name", field: "displayName", width: "200", clipMode: "EllipsisWithTooltip" },
                { headerText: "Instances", field: "count", width: "100" },
              ]}>
            </TableCard>
          </div>
          <div className="col-xl-6 col-sm-12">
            <TableCard
              id={`all_teams_external_apps`}
              title={ReportStrings.apps.teamsList.title}
              description={ReportStrings.apps.teamsList.description}
              colour="danger"
              items={teamsWithExternalApps?.results}
              enablePaging={true}
              currentPage={teamsWithExternalApps?.currentPage}
              totalPages={teamsWithExternalApps?.pageCount}
              onTitleClick={this.props.onTeamClick}
              columnInfo={[
                { headerText: "Name", field: "displayName", width: "100", clipMode: "EllipsisWithTooltip" },
              ]}
              onPageChange={teamsTablePageChange} />
          </div>
        </div>
      </div>
    );
  }
}
