import * as React from 'react';

export interface AlertProps {
    message:string;
}

export interface AlertState {
}

export default class Alert extends React.Component<AlertProps, AlertState> {
  constructor(props: AlertProps) {
    super(props);

    this.state = {
    }
  }

  public render() {
    return (
        <div className="alert alert-danger fixed-bottom alert-dismissible fade show" role="alert">
        <strong>Something went wrong.</strong>&nbsp;{this.props.message}
        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    );
  }
}
