import { IProductInfo } from "../models/ProductInfo";
import { IProductService } from "./IProductService";

export class ProductService implements IProductService {
    async getProductInfo(product: string): Promise<IProductInfo> {
        var url = `/api/product/info/${product}`;
        var response = await fetch(url);
        return await response.json() as IProductInfo;
    }

    async install(product: string, uniqueId: string, data: any, acccessToken: string, site?: string, page?: string): Promise<any> {
        let siteParam = '';
        if (site) {
            siteParam = `?site=${site}`;
        }

        let pageParam = '';
        if(page) {
            pageParam = siteParam != '' ? `&page=${page}` : `?page=${page}`
        }

        var url = `/api/product/install/${product}/${uniqueId}${siteParam}${pageParam}`;
        var body = JSON.stringify(data);

        const options = {
            method: "POST",
            headers: {
              'Authorization': `bearer ${acccessToken}`,
              'Content-Type': 'application/json'
            },
            body: body
        };

        var response = await fetch(url, options);
        return await response.json();
    }
}