import React, { Component } from 'react';
import { NavMenu } from './NavMenu';
import Footer from './Footer';
import AuthProvider from '../auth/AuthProvider';

interface ILayoutProps {
  authProvider?: AuthProvider;
}

export class Layout extends Component<ILayoutProps, {}> {
  static displayName = Layout.name;

  render() {
    return (
      <div>
        <NavMenu authProvider={this.props.authProvider}/>
        {this.props.children}
        <Footer />
      </div>
    );
  }
}
